import store from '../store/index'
import { Api } from '../backoffice/api/api.js'
import { payAuthorizeNet } from '../backoffice/api/payments.js'
import { Utils } from '../backoffice/utils/utils'
//import moment from 'moment-timezone';
import { i18n } from '@/plugins/i18n'
import { DateTime } from 'luxon'

export var Commons = {
  order: store.state.order,
  cart: store.state.cart,
  configuration: store.state.configuration,
  restaurantActive: store.state.restaurantActive,
  clientId: store.state.customer._id,
  CustomerName: store.state.customer.Name,
  email: store.state.customer.EmailAddress,
  phone: store.state.customer.Phone,
  i18n: i18n,
  allTypeOrder: {
    Delivery: i18n.t('frontend.app.deliver'),
    PickUp: i18n.t('frontend.app.pickup'),
    'On Table': i18n.t('frontend.app.table'),
    Curbside: i18n.t('frontend.app.curbside'),
    Ecom: 'Ecom'
  },
  allOrderStates: [
    i18n.t('frontend.order.state0'),
    i18n.t('frontend.order.state1'),
    i18n.t('frontend.order.state2'),
    i18n.t('frontend.order.state3'),
    i18n.t('frontend.order.state4'),
    i18n.t('frontend.order.state5'),
    i18n.t('frontend.order.state6'),
    i18n.t('frontend.order.state7'),
    i18n.t('frontend.order.state8'),
    i18n.t('frontend.order.state9')
  ],
  allQuotesStates: [
    i18n.t('frontend.order.pendingAcceptance'),
    i18n.t('frontend.order.accepted')
  ],

  // getOtherCharges: async function () {
  //   this.cart = store.state.cart;

  //   const otherCharges = []
  //   for (const obj of this.cart) {
  //     const resp = await Api.findProductCharges(obj.ProductId, obj.Cant);
  //     if (resp.status === 200 && resp.data.length > 0) {
  //       otherCharges.push(resp.data[0]);
  //     }
  //   }
  //   return otherCharges;
  // },

  getOtherCharges: async function () {
    this.cart = store.state.cart
    const otherCharges = []
    const charges = await this.getCharges()
    const prodState = store.state.products
    for (const obj of this.cart) {
      const charge = charges.filter((c) => {
        let arrIds = []
        let arrCat = []
        const dataProd = prodState.find((p) => p._id == obj.ProductId)
        if (c.ProductId.includes('[')) {
          arrIds = JSON.parse(c.ProductId)
        } else {
          arrIds.push(c.ProductId)
        }
        if (c.CategoryId) {
          if (c.CategoryId.includes('[')) {
            arrCat = JSON.parse(c.CategoryId)
          } else {
            arrCat.push(c.CategoryId)
          }
        }

        let isValid =
          (arrIds.includes(obj.ProductId) ||
            arrCat.includes(dataProd.CategoryId)) &&
          obj.Cant >= c.ProductMin &&
          obj.Cant <= c.ProductMax

        return isValid
      })
      if (charge.length > 0) {
        otherCharges.push(...charge)
      }
    }
    return otherCharges
  },

  getCharges: async function () {
    const resp = await Api.getCharges()
    return resp.data
  },

  //This function will add other charges on invoice and quotes
  getOtherChargesByProduct: async function (ProductId, Cant) {
    const resp = await Api.findProductCharges(ProductId, Cant)
    return resp.data
  },

  changeRestaurant: async function (restaurantId) {
    //console.log('JQD', "Commons changeRestaurant", restaurantId);
    store.commit('setBackConfig', {})
    Api.setRestaurantId(restaurantId)

    const promise = []

    if (store.state.allRestaurant.length === 0)
      promise.push(this.getAllRestaurant()) //await this.getAllRestaurant();

    if (store.state.staffName) {
      promise.push(this.getAllRestaurantCustomers()) //this.getAllRestaurantCustomers();
      promise.push(this.getCompleteRestaurant()) //await  this.getCompleteRestaurant();
      await Promise.all(promise)
      this.defaultData(restaurantId)
    } else {
      this.getCompleteRestaurant()
      await this.defaultData(restaurantId)
      store.commit('setHasRating', false)
      store.commit('setRestaurantId', restaurantId)

      if (this.clientId) promise.push(this.GetClientInfo())

      if (
        store.state.guess.Name &&
        store.state.guess.Phone &&
        store.state.guess.EmailAddress
      ) {
        if (
          store.state.guess.Name !== '' &&
          store.state.guess.Phone !== '' &&
          store.state.guess.EmailAddress !== ''
        ) {
          this.alertSubscription(
            store.state.guess.EmailAddress,
            store.state.guess.Name,
            store.state.guess.Phone
          )
        }
      }
      promise.push(this.getStaff())
      await Promise.all(promise)
    }

    return true
  },

  getAllRestaurant: async function () {
    try {
      const response = await Api.fetchAll('Restaurant')
      if (response.status === 200) {
        let restaurants = response.data
        //console.log('JQD', "getAllRestaurant", response);
        const allRest = restaurants.filter((r) => r.Online === true)
        store.commit('setAllRestaurant', JSON.parse(JSON.stringify(allRest)))
      }
    } catch (error) {
      error
    }
  },

  async GetClientInfo() {
    const response = await Api.findCustomerInfo(this.clientId)
    if (response) {
      this.getOrders(response.orders)
      this.getTickets(response.tickets)
      this.getListReservation(response.reservations)
      this.getCredit(response.credits)
    }
  },

  async getOrders(datas) {
    let data = datas ? datas : null
    if (!datas && this.clientId) {
      const response = await Api.getCustomerOrders(this.clientId)
      if (response.status === 200) data = response.data
    }
    store.commit('setAllOrder', data)
  },

  async getTickets(datas) {
    let data = datas ? datas : null

    if (!datas && this.clientId) {
      const response = await Api.getCustomerTickets(this.clientId)
      if (response.status === 200) data = response.data
    }

    if (data.length > 0) {
      store.commit('setAllTickets', data)
      this.cart = JSON.parse(JSON.stringify(data[0].Products))
      store.commit('setCart', this.cart)
      this.order = JSON.parse(JSON.stringify(data[0]))
      store.commit('setOrder', this.order)
    } else store.commit('setAllTickets', [])
  },

  async getAllCredit(datas) {
    let data = datas ? datas : null
    if (!datas && this.clientId) {
      const response = await Api.getAllCustomerCredit(this.clientId)
      if (response.status === 200) data = response.data
    }
    if (data.length > 0) {
      store.commit('setAllCustomerCredit', data)
    }
  },

  async getCredit(datas = null) {
    if (this.clientId == '') return false
    await this.getAllCredit(datas)
    const activeCredit = store.state.allCustomerCredit.find(
      (credit) => (credit.Active = true)
    )
    if (activeCredit) store.commit('setCustomerCredit', activeCredit)
    else store.commit('setCustomerCredit', {})
  },

  async updateCustomerCredit(debt, model, modelId) {
    store.state.customerCredit.Debt += debt

    if (model && modelId) {
      const used = {
        Model: model,
        ModelId: modelId,
        Total: debt
      }
      store.state.customerCredit.UsedIn.push(used)
    }
    try {
      await Api.putIn('customercredit', store.state.customerCredit)
      await this.getAllCredit()
    } catch (error) {
      error
    }
  },

  getListReservation: async function (datas) {
    let data = datas ? datas : null

    if (!datas && this.clientId) {
      const response = await Api.getCustomerReservations(this.clientId)
      if (response.status === 200) data = response.data
    }
    store.commit('setAllReservations', data)
  },

  async defaultData(restaurantId) {
    store.commit('restartFront')
    document.querySelector('style').innerHTML += Utils.defaultStyles

    if (store.state.restaurantActive.payMethod === 'SHIFT4')
      this.getWalletInformation()

    const response = await Api.findRestaurantToFront()
    if (response) {
      this.getConfig(response.setting)
      this.fetchMenus({
        menuConCaterin: response.menuConCaterin,
        categoryMenuConCaterin: response.categoryMenuConCaterin,
        menuSinCaterin: response.menuSinCaterin,
        categoryMenuSinCaterin: response.categoryMenuSinCaterin
      }),
        this.restaurantData(restaurantId, response.restaurant)
      this.fetchProducts(response.product)
      this.fetchVariants(response.variantgroup)
      this.fetchCategories(response.allCategoryActiveMenu)
      this.getTax(response.taxavailable),
        this.getShipping(response.shipping),
        this.getSubscribers(response.subscriber)
      this.getAllTaxes(response.tax)
      this.getAllAbouts(response.about)
      this.getAllDigitalSignages(response.digitalSignage)
    }
  },

  getConfig: async function (datas) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.fetchAll('Setting')
      if (response.status === 200) data = response.data[0]
    }

    //console.log('JQD', "getConfig", data);

    var allStyles = data.AllStyles
    document.querySelector('style').innerHTML += allStyles

    const configuration = {
      showCooker: data.ShowCooker,
      selectPickHour: data.SelectPickHour,
      deliveryZone: data.DeliveryZone,
      zipCodes: data.ZipCodes,
      CanViewGeolocation: data.CanViewGeolocation,
      minTimeToCook: data.MinTimeToCook,
      minHour: data.PickFrom,
      maxHour: data.PickTo,
      viewCatering: data.ViewCatering,
      tipRequire: data.TipRequire || false,
      tips: data.Tips || [],
      viewRating: data.ViewRating || false,
      payForQuotation: data.PayForQuotation || false,
      percentPayForQuotation: data.PercentPayForQuotation || 0,
      minAmountCateringDelivery: data.MinAmountCateringDelivery || 0,
      minAmoutCatering: data.MinAmoutCatering || 0,
      cateringMarginDays: data.CateringMarginDays || 2,
      cateringStates: data.CateringStates || [],
      zipCodesExcludes: data.ZipCodesExcludes || [],
      cateringEvents: data.CateringEvents || [],
      viewReservation: data.ViewReservation || false,
      viewDigitalSigned: data.ViewDigitalSigned || false,
      viewCustomerReservation: data.ViewCustomerReservation || false,
      minDayToReservation: parseInt(data.MinDayToReservation) || 0,
      reservationDaysAndTime: data.ReservationDaysAndTime || [],
      viewDelivery: data.ViewDelivery || false,
      viewOnTable: data.ViewOnTable || false,
      viewCurbside: data.ViewCurbside || false,
      hasReservationQuotation: data.HasReservationQuotation || false,
      payForReservationQuotation: data.PayForReservationQuotation || 0,
      showOtherRestaurant: data.ShowOtherRestaurant || false,
      ads: data.Ads || [],
      hasDeliveryPayment: data.HasDeliveryPayment || false,
      tableDesign: data.TableDesign || [],
      tablesChoose: data.TablesChoose || false,
      reservationByTable: data.ReservationByTable || false,
      reservationByStaff: data.ReservationByStaff || false,
      timezone: data.Timezone,
      backgroundImage: data.BackgroundImage,
      isEcom: data?.isEcom || false
    }

    store.commit('setConfiguration', configuration)
  },

  async getCategoryMenu(menulist) {
    if (menulist.length > 0) {
      const id = menulist[0]._id
      const response = await Api.categoryByMenuId(id)
      if (response.status === 200) {
        return response.data
      }
    }
  },

  async fetchMenus(datas) {
    let data = datas ? datas : null
    if (datas) {
      const menuSC = datas.menuSinCaterin.filter((m) => m.Active === true)
      store.commit('setMenuSinCatering', menuSC)
      const categoryMenuListSinCatering = data.categoryMenuSinCaterin
      store.commit(
        'setCategoryMenuListSinCatering',
        categoryMenuListSinCatering
      )

      const menuCC = datas.menuConCaterin.filter((m) => m.Active === true)
      store.commit('setMenuConCatering', menuCC)
      const menuListConCatering = data.categoryMenuConCaterin
      store.commit('setCategoryMenuConCatering', menuListConCatering)
    }

    try {
      const responseSC = await Api.menuSinCaterin()
      if (responseSC.status === 200) {
        const menuSC = responseSC.data.filter((m) => m.Active === true)
        store.commit('setMenuSinCatering', menuSC)
        const categoryMenuListSinCatering = await this.getCategoryMenu(menuSC)
        store.commit(
          'setCategoryMenuListSinCatering',
          categoryMenuListSinCatering
        )
      }

      const responseCC = await Api.menuConCaterin()
      if (responseCC.status === 200) {
        const menuCC = responseCC.data.filter((m) => m.Active === true)
        store.commit('setMenuConCatering', menuCC)
        const menuListConCatering = await this.getCategoryMenu(menuCC)
        store.commit('setCategoryMenuConCatering', menuListConCatering)
      }
    } catch (error) {
      error
    }
  },

  restaurantData: async function (restaurantId, datas = null) {
    let flag = false
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.fetchById('Restaurant', restaurantId)
      if (response.status === 200) {
        data = response.data
      }
    }

    //console.log("JQD Commons.restaurantData", data);

    const dataRestaurant = {
      restaurantId: restaurantId,
      restaurantName: data.Name,
      restaurantZipCode: data.ZipCode,
      restaurantUrl: data.Url,
      restaurantPhone: data.Phone,
      restaurantLogo: data.ImageUrl,
      restaurantEmail: data.Email,
      restaurantAddress: data.Address,
      currency: data.Currency,
      hasPaymentCardConfig: data.HasPaymentCardConfig || false,
      restaurantWeb: data.Web || '',
      restaurantDaysAndTime: data.RestaurantDaysAndTime || [],
      customHours: data.CustomHours || false,
      customHoursText: data.CustomHoursText || '',
      payMethod: data.PayMethod || '',
      PayMethodACH: data.PayMethodACH || '',
      rating: data.rating || [],
      UrlLocation: data.UrlLocation || '',
      Fax: data.Fax || '',
      ServiceBussines: data.ServiceBussines || false,
      RestaurantBussines: data.RestaurantBussines || false,
      businessType: data.RestaurantBussines || 'Hospitality',
      //isEcom: data.isEcom || false,
      timezone: data.Timezone,
      backgroundImageUrl: data?.backgroundImageUrl?? ""
    }

    flag = data.Online || false

    const fcb = data.Sociasls.findIndex((pr) => pr.SocialName === 'Facebook')
    if (fcb !== -1)
      dataRestaurant.restaurantFacebok = data.Sociasls[fcb].SocialUrl
    const twt = data.Sociasls.findIndex((pr) => pr.SocialName === 'Twitter')
    if (twt !== -1)
      dataRestaurant.restaurantTwitter = data.Sociasls[twt].SocialUrl
    const itg = data.Sociasls.findIndex((pr) => pr.SocialName === 'Instagram')
    if (itg !== -1)
      dataRestaurant.restaurantInstagram = data.Sociasls[itg].SocialUrl
    const ytb = data.Sociasls.findIndex((pr) => pr.SocialName === 'Youtube')
    if (ytb !== -1)
      dataRestaurant.restaurantYoutube = data.Sociasls[ytb].SocialUrl

    store.commit('setRestaurantActive', dataRestaurant)

    return flag
  },

  getAllTaxes: async function (datas) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.fetchAll('Tax')
      if (response.status === 200) data = response.data
    }
    const taxes = data.filter((t) => t.Available === true)
    store.commit('setAllTaxes', taxes)
  },

  getAllAbouts: async function (datas) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.fetchAll('About')
      if (response.status === 200) data = response.data
    }
    store.commit('setAbout', data)
  },

  getAllDigitalSignages: async function (datas) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.fetchAll('DigitalSignage')
      if (response.status === 200) data = response.data
    }
    store.commit('setDigitalSignage', data)
  },

  async getAllRestaurantCustomers() {
    try {
      const result = await Api.getRestaurantCustomer()
      if (result.status === 200) {
        store.commit(
          'setRestaurantCustomers',
          JSON.parse(JSON.stringify(result.data))
        )
      }
    } catch (error) {
      error
    }
  },

  // fetchProducts: async function (datas = null) {
  //   let data = datas ? datas : null;

  //   if (!datas) {
  //     const response = await Api.fetchAll("Product");
  //     if (response.status === 200) data = response.data;
  //   }

  //   const allSpecialProd = data;
  //   allSpecialProd.forEach((prod) => {
  //     prod.OriginalRetailPrice = prod.RetailPrice;
  //     if (prod?.SpecialPrice > 0) prod.RetailPrice = prod.SpecialPrice;

  //     if (prod?.SalePrice > 0) prod.RetailPrice = prod.SalePrice;
  //   });

  //   store.commit("setProducts", allSpecialProd);
  // },

  fetchProducts: async function (datas = null) {
    try {
      let data = datas ? datas : null

      // Validate the input 'datas'
      if (datas) {
        if (!Array.isArray(datas)) {
          console.error("Invalid data: 'datas' should be an array.")
          return
        }
        datas.forEach((prod, index) => {
          if (typeof prod !== 'object' || !prod.RetailPrice) {
            console.warn(
              `Product at index ${index} is missing expected properties.`
            )
          }
        })
      }

      // If no data is passed, fetch it from the API
      if (!datas) {
        const response = await Api.fetchAll('Product')

        if (response.status === 200) {
          data = response.data
        } else {
          console.error(`Failed to fetch products: ${response.statusText}`)
          return // Exit if the API call fails
        }
      }

      // Process the products
      if (data && Array.isArray(data)) {
        const allSpecialProd = data.map((prod) => {
          // Ensure the product object has the necessary properties
          prod.OriginalRetailPrice = prod.RetailPrice || 0

          // Apply special price if available
          if (prod?.SpecialPrice > 0) {
            prod.RetailPrice = prod.SpecialPrice
          }

          // Apply sale price if available
          if (prod?.SalePrice > 0) {
            prod.RetailPrice = prod.SalePrice
          }

          return prod
        })

        // Commit the processed products to the store
        store.commit('setProducts', allSpecialProd)
      } else {
        console.error('No valid product data found to process.')
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching or processing products:',
        error
      )
      // Optionally, you can display an alert or notification to the user here
    }
  },

  fetchVariants: async function (datas) {
    let data = datas ? datas : null

    if (!datas) {
      const response = await Api.fetchAll('Variantgroup')
      if (response.status === 200) data = response.data
    }
    const allSpecialVariants = data
    allSpecialVariants.forEach((variant) => {
      variant.Variants.forEach((prod) => {
        if (prod?.SpecialPrice > 0) prod.RetailPrice = prod.SpecialPrice

        if (prod?.SalePrice > 0) prod.RetailPrice = prod.SalePrice
      })
    })

    store.commit('setVariants', data)
  },

  fetchCategories: async function (datas) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.getAllCategoryActiveMenu()
      if (response.status === 200) data = response.data
    }
    store.commit('setCategories', data)
  },

  getTax: async function (datas) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.getAvailbleTax()
      if (response.status === 200) data = response.data
    }
    const tax = {
      taxesName: data.Name,
      taxes: data.Percentage
    }
    store.commit('setTax', tax)
  },

  getShipping: async function (datas) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.getAvailableShipping()
      if (response.status === 200) data = response.data
    }
    const shipping = {
      shippingName: data.Name,
      shipping: parseFloat(data.Price)
    }
    store.commit('setShipping', shipping)
  },

  getSubscribers: async function (datas = null) {
    let data = datas ? datas : null
    if (!datas) {
      const response = await Api.fetchAll('Subscriber')
      if (response.status === 200) data = response.data
    }
    store.commit('setSubscribers', data)
  },

  async getWalletInformation() {
    const basket = { invoice: 666, total: 100 }
    try {
      const ipClient = await Api.getClientIp()

      const res = await Api.walletInformation(
        basket,
        store.state.restaurantActive.restaurantId,
        ipClient.data.ip
      )
      if (res.status === 200 && res.statusText === 'OK') {
        const walletConfig = {
          merchantId: res.data.walletConfig.merchantID.toString(),
          gateway: res.data.walletConfig.googlePay.gateway,
          allowedAuthMethods:
            res.data.walletConfig.googlePay.allowedAuthMethods,
          allowedCardNetworks:
            res.data.walletConfig.googlePay.allowedCardNetworks,
          currencyCode: res.data.walletConfig.currencyCode,
          countryCode: res.data.walletConfig.countryCode
        }

        store.commit('setWalletConfig', walletConfig)
      }
      return false
    } catch (error) {
      error
    }
  },

  getFormatPrice(price) {
    const result = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: store.state.restaurantActive.currency || 'USD'
    }).format(price)
    return result
  },

  // getDate(thisDate){
  //   return  moment.tz(thisDate, moment.tz.guess()).format('MM-DD-YYYY')
  // },

  //  getHour(thisHour){
  //   return  moment.tz(thisHour, moment.tz.guess()).format('hh:mm A')
  // },
  getDate(thisDate) {
    return DateTime.fromISO(thisDate, { zone: 'auto' }).toFormat('MM-DD-YYYY')
  },

  getHour(thisHour) {
    return DateTime.fromISO(thisHour, { zone: 'auto' }).toFormat('hh:mm a')
  },

  htmlToSendEmailOrder(order) {
    order.Products.forEach((orderItem) => {
      if (orderItem.applySPDiscount) {
        order.hasSpDiscount = true
        return
      }
    })
    //console.log('JQD', "htmlToSendEmailOrder", order);
    let totalWithoutQuotation = 0
    if (order.QuotationPayment)
      totalWithoutQuotation = order.Total - order.QuotationPayment
    else totalWithoutQuotation = order.Total

    // var date = moment.tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');

    // if(order.Date)
    //   date = moment.tz(order.Date, moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');
    var date = DateTime.now().toFormat('MM/dd/yyyy HH:mm:ss')

    if (order.Date) {
      date = DateTime.fromJSDate(new Date(order.Date)).toFormat(
        'MM/dd/yyyy HH:mm:ss'
      )
    }

    let urlCurbside = ''
    let orderInfo = ''
    if (order.OrderType == 'Delivery') orderInfo = order.AddressToDeliver
    // if(order.OrderType == 'PickUp')
    //     orderInfo = moment.tz(order.DateToPick, moment.tz.guess()).format('MM-DD-YYYY') + ' |  ' + moment.tz(order.HourToPick, moment.tz.guess()).format('hh:mm A')
    if (order.OrderType === 'PickUp')
      orderInfo =
        DateTime.fromISO(order.DateToPick, { zone: 'auto' }).toFormat(
          'MM-dd-yyyy'
        ) +
        ' | ' +
        DateTime.fromISO(order.HourToPick, { zone: 'auto' }).toFormat('hh:mm a')
    if (order.OrderType == 'On Table') orderInfo = order.tableService

    if (order.OrderType === 'Curbside') {
      orderInfo =
        DateTime.fromISO(order.DateToPick, { zone: 'auto' }).toFormat(
          'MM-dd-yyyy'
        ) +
        ' | ' +
        DateTime.fromISO(order.HourToPick, { zone: 'auto' }).toFormat('hh:mm a')
      if (order._id) {
        urlCurbside = `${Api.baseUrl}/?rid=${store.state.restaurantActive.restaurantId}&curbside=${order._id}`
      }
    }

    var html = ' <html><head>'
    html +=
      '<style> .progressBar { width: 100%;  border-bottom: 1px solid black;display: list-item;list-style: unset; padding: 0}'
    html +=
      '.progressBar li {list-style-type: none; float: left; position: relative; text-align: center; margin:0}'
    html +=
      '.progressBar li .before {content: " "; line-height: 30px; border-radius: 50%; width: 30px; height: 30px; border: 1px solid #ddd;'
    html +=
      'display: block;text-align: center;margin: 0 auto 10px;background-color: white}'
    html +=
      '.progressBar li .after { content: "";position: absolute;width: 100%;height: 4px;background-color: #ddd;top: 15px;left: -50%;z-index: -1;}'
    html +=
      '.progressBar li .one .after {content: none;}.progressBar li.active {color: black;}'
    html +=
      '.progressBar li.active .before { border-color: #63ee68; background-color: #63ee68}.progressBar .active:after {background-color: #4ca44f;} </style>'
    html += `<title>Order - ${store.state.restaurantActive.restaurantName}</title>`
    html += '</head><body><div >'
    html += '<table  align=center style="width: 90%;">'
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h2 style="margin-top: 0; margin-bottom: 0;">${store.state.restaurantActive.restaurantName}</h2>  `
    html += `<img src="${store.state.restaurantActive.restaurantLogo}" style="max-width: 100px; margin-top: 0; margin-bottom: 0;"></img> `
    html += `<h2 style="margin-top: 0; margin-bottom: 0;">Order</h2>  `
    html += `</td>`
    html += `</tr>`
    html += '<tr><td colspan=6 >'
    if (order.Payment) {
      html += `<br> <h2 style="margin-top: 0; margin-bottom: 0;"> ${i18n.t(
        'frontend.order.payment'
      )}: </h2>`
      for (const payment of order.Payment) {
        if (payment.paymentInfo) {
          html += ` <h4 style="margin-top: 0; margin-bottom: 0;"> 
              ${i18n.t(
                'frontend.order.total'
              )}: <strong>  ${this.getFormatPrice(payment.total)} </strong>  |
              ${i18n.t('frontend.order.transId')}: <strong>  ${
                payment.paymentInfo.transId
              } </strong>  |
              ${i18n.t('frontend.order.paymentMethod')}: <strong>  ${
                payment.PaymentMethod
              } ${
                payment.paymentInfo.amountToLeave
                  ? '$' + payment.paymentInfo.amountToLeave
                  : ''
              } </strong> 
            </h4>`

          if (payment.PaymentMethod.indexOf('Cash') >= 0) {
            html += `<h4 style="margin-top: 0; margin-bottom: 0;">Change: ${this.getFormatPrice(
              payment.paymentInfo.amountToLeave - payment.total
            )} </h4>`
          }
        }
      }
    }
    html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
      'frontend.order.date'
    )}: ${date} </h4><hr>`
    html += `<h4 style="margin-top: 0; margin-bottom: 4px;">${i18n.t(
      'frontend.order.client'
    )}: ${order.CustomerName} </h4>`
    html += `<h4 style="margin-top: 0; margin-bottom: 4px;">${i18n.t(
      'frontend.orderType.phone'
    )}: ${order.CustomerPhone} </h4>`
    if (order.OrderType)
      html += `<h4 style="margin-top: 0; margin-bottom: 4px;">${i18n.t(
        'frontend.order.orderFor'
      )} ${this.allTypeOrder[order.OrderType]}: ${orderInfo} </h4>`
    if (order.State)
      html += `<h4 style="margin-top: 0; margin-bottom: 4px;">${i18n.t(
        'frontend.order.orderState'
      )}: ${this.allOrderStates[order.State]} </h4>`
    if (order.State == 6)
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.order.cancelReason'
      )}: ${order.CancelNote}</h4><hr>`
    if (urlCurbside !== '') {
      html += `<h2 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.home.curbsideDetail'
      )}</h2>`
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.licencePlate'
      )}: ${order.LicencePlate} </h4>`
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.vehicleModel'
      )}: ${order.VehicleModel} </h4>`
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.vehicleColor'
      )}: ${order.VehicleColor} </h4>`
      html += `<a 
            href="${urlCurbside}"
            target="_blank" 
            rel="noopener noreferrer" 
            data-auth="NotApplicable" 
            >${i18n.t('frontend.tooltips.getToRestaurant')}</a><hr>`
    }
    html += `</td></tr>`
    html += `<tr ><td colspan=6 ><h2 style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
      'frontend.order.productsOrdered'
    )}</strong></h2></td></tr> <tr></tr>`
    for (var i = 0; i < order.Products.length; i++) {
      html += `<tr ><td  colspan=4 style="margin-top: 0; margin-bottom: 0; border-bottom: 1px solid #dbd1d1;" ><strong >${order.Products[i].Name} `
      if (order.Products[i].VariantSelected)
        if (order.Products[i].VariantSelected.name)
          html += `${order.Products[i].VariantSelected.name}`
      html += `</strong>`
      if (this.hasSomeNote(order.Products[i]))
        html += `<p style="background: #f1f1004d;">${this.getIngredientNote(
          order.Products[i]
        )} </br> ${order.Products[i].Note}</p> `
      let colWidth = order.hasSpDiscount ? '16' : '25'
      html += `</td><td style="margin-top: 0; margin-bottom: 0; width: ${colWidth}%;border-bottom: 1px solid #dbd1d1;" > <p >( ${
        order.Products[i].Cant
      } X ${this.getFormatPrice(order.Products[i].Price)} )</p> </td>`
      if (order.hasSpDiscount) {
        html += `<td style="margin-top: 0; margin-bottom: 0; width: ${colWidth}%; border-bottom: 1px solid #dbd1d1;"> <p > ${
          order.Products[i].applySPDiscount
            ? order.Products[i].spDiscountName +
              ' (' +
              this.getFormatPrice(order.Products[i].spDiscount * -1) +
              ')'
            : ''
        }</p> </td>`
      }
      html += `<td style="margin-top: 0; margin-bottom: 0; width: ${colWidth}%;border-bottom: 1px solid #dbd1d1;"> <p > ${this.getFormatPrice(
        order.Products[i].Price * order.Products[i].Cant -
          (order.Products[i]?.spDiscount ? order.Products[i].spDiscount : 0)
      )}</p> </td>`
      html += `</tr>`
      if (order.Products[i].Aggregates.length > 0) {
        html += `<tr style="padding: 20px 35px;"> ${i18n.t(
          'frontend.home.aggregateFree'
        )}: ${(order.Products[i].CantAggr =
          order.Products[i].AggregatesCant * order.Products[i].Cant)} </tr>`

        for (var a = 0; a < order.Products[i].Aggregates.length; a++) {
          let agg = order.Products[i].Aggregates[a]
          html += `<tr ><td  colspan=4 style="margin-top: 0; margin-bottom: 0; width: 50%;border-bottom: 1px solid #dbd1d1;" ><p style="padding-left: 20px; margin-top: 0; margin-bottom: 0;">${
            agg.Name
          } <br> ${this.getFormatPrice(agg.RetailPrice)}</p>`
          html += `</td><td style="margin-top: 0; margin-bottom: 0; width: 25%;border-bottom: 1px solid #dbd1d1;" > <p style="margin-top: 0; margin-bottom: 0;"> ${agg.Cant}</p> </td>`
          html += `<td style="margin-top: 0; margin-bottom: 0; width: 25%;border-bottom: 1px solid #dbd1d1;"> <p style="margin-top: 0; margin-bottom: 0;"> ${this.getFormatPrice(
            agg.AllTotal
          )}</p> </td></tr >`
        }
      }
    }

    if (order.OtherCharges) {
      if (order.OtherCharges.length > 0) {
        html += `<tr ><td colspan=6 ><h4 style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
          'frontend.order.otherCharges'
        )}</strong></h4></td></tr>`
        for (var e = 0; e < order.OtherCharges.length; e++) {
          html += ` <tr ><td colspan=5 style="width: 75%;border-bottom: 1px solid #dbd1d1;"><p style="margin-top: 0; margin-bottom: 0;">${
            order.OtherCharges[e].Name
          }</p></td> <td style="border-bottom: 1px solid #dbd1d1;"> <p style="margin-top: 0; margin-bottom: 0;"> ${this.getFormatPrice(
            order.OtherCharges[e].Price
          )}</p></td></tr>`
        }
      }
    }
    if (order.OtherChargesC) {
      if (order.OtherChargesC.length > 0) {
        html += `<tr ><td colspan=6 ><h4 style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
          'frontend.order.otherCharges'
        )}</strong></h4></td></tr>`
        for (var e = 0; e < order.OtherChargesC.length; e++) {
          html += ` <tr ><td colspan=5 style="width: 75%;border-bottom: 1px solid #dbd1d1;"><p style="margin-top: 0; margin-bottom: 0;">${
            order.OtherChargesC[e].Name
          }</p></td> <td style="border-bottom: 1px solid #dbd1d1;"> <p style="margin-top: 0; margin-bottom: 0;"> ${this.getFormatPrice(
            order.OtherChargesC[e].Price
          )}</p></td></tr>`
        }
      }
    }
    html += `<tr ><td colspan=5 ><p style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
      'frontend.order.subtotal'
    )}</strong></p></td> <td > <p style="margin-top: 0; margin-bottom: 0;"> ${this.getFormatPrice(
      order.SubTotal
    )}</p></td></tr>`
    // let taxSubtotal=0;
    // for (var g = 0; g < order.Products.length; g++) {
    //   const prod=order.Products[g];
    //   if(prod?.isTaxable){
    //     console.log({prod})
    //     taxSubtotal+=prod.Cant*prod.Price;
    //   }
    // }
    // for (var g = 0; g < order.AllTaxes.length; g++) {
    //   html += `<tr><td  colspan=5><p  style="margin-top: 0; margin-bottom: 0;"><strong>${
    //     order.AllTaxes[g].Name
    //   } (${
    //     order.AllTaxes[g].Percentage
    //   }%) </strong></p></td> <td > <p >${this.getFormatPrice(
    //     (order.AllTaxes[g].Percentage * taxSubtotal) / 100
    //   )}</p> </td></tr>`;
    // }
    for (var g = 0; g < order.AllTaxes.length; g++) {
      html += `<tr><td  colspan=5><p  style="margin-top: 0; margin-bottom: 0;"><strong>${
        order.AllTaxes[g].Name
      } (${
        order.AllTaxes[g].Percentage
      }%) </strong></p></td> <td > <p >${this.getFormatPrice(
        (order.AllTaxes[g].Percentage * order.SubTotal) / 100
      )}</p> </td></tr>`
    }
    if (order.OrderType == 'Delivery')
      html += `<tr ><td colspan=5 ><p  style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
        'frontend.order.deliver'
      )}</strong></p></td><td  ><p style="margin-top: 0; margin-bottom: 0;"> ${this.getFormatPrice(
        order.Shipping
      )}</p></td></tr>`

    if (order.Tip) {
      html += `<tr ><td  colspan=5 ><p style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
        'frontend.order.tip'
      )} ${
        order.Tip
      }%</strong></p></td><td ><p style="margin-top: 0; margin-bottom: 0;">${this.getFormatPrice(
        (order.Tip * order.SubTotal) / 100
      )} </p> </td></tr>`
    }

    let savings =
      (order?.totalSpDiscount ? order.totalSpDiscount : 0) +
      (order?.Discount ? order.Discount : 0)
    if (savings) {
      html += `<tr><td colspan=5 style="border-bottom: 1px solid #dbd1d1;"><p  style="margin-top: 0; margin-bottom: 0;"><strong>Total Savings In This Order</strong></p></td> <td style="border-bottom: 1px solid #dbd1d1;"> ${this.getFormatPrice(
        savings
      )} </td></tr>`
    }

    html += `<tr><td colspan=5 style="border-bottom: 1px solid #dbd1d1;"><p  style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
      'frontend.order.total'
    )}</strong></p></td> <td style="border-bottom: 1px solid #dbd1d1;"> <strong > ${this.getFormatPrice(
      order.Total
    )}</strong> </td></tr>`

    if (order.QuotationPayment)
      html += `<tr style="border-bottom: 1px solid #399922;"><td colspan=5 ><p  ><strong>Quotation</strong></p></td> <td > <strong >${this.getFormatPrice(
        order.QuotationPayment
      )}</strong> </td></tr>`
    if (order.PendingPayment)
      html += `<tr style="border-bottom: 1px solid #ff5500;"><td colspan=5 style="border-bottom: 1px solid #ff5500;"><p  ><strong>Pending</strong></p></td> <td  style="border-bottom: 1px solid #ff5500;"> <strong >${this.getFormatPrice(
        order.PendingPayment
      )}</strong> </td></tr>`
    if (order.PendingPayment > 0 && order.Deadline) {
      html += `<tr ><td colspan=6 ><h4 style="margin-top: 0; margin-bottom: 0;"><strong>Parcial Payment</strong></h4></td></tr>`
      for (var dead = 0; dead < order.Deadline.length; dead++) {
        html += ` <tr ><td colspan=3 style="border-bottom: 1px solid #dbd1d1;"><p >${order.Deadline[dead].Date}  </p></td> `
        html += ` <td colspan=3 style="border-bottom: 1px solid #dbd1d1;"><strong >  ${
          order.Deadline[dead].Percent
        }%  =  ${this.getFormatPrice(
          (totalWithoutQuotation * order.Deadline[dead].Percent) / 100
        )}</strong></td> `
        if (order.Deadline[dead].State === 1)
          html += ` <td style="border-bottom: 1px solid #dbd1d1;"> <strong  style= "color: #399922;  ">Payed</strong></td>`
        else
          html += ` <td style="border-bottom: 1px solid #dbd1d1;"><strong  style= "color: #ff5500; ">Pending</strong> </td>`
        html += ` </tr>`
      }
    }

    if (order.Note) {
      html += `<tr ><td style="width: 20%;border-bottom: 1px solid grey;"><h4 >${i18n.t(
        'frontend.order.notes'
      )}</h4></td><td colspan=5 style="width: 80%;border-bottom: 1px solid grey;" ><p >${
        order.Note
      }</p></td></tr>`
    }

    //put the reservation
    if (order.OrderType == 'Ecom') {
      html += '<tr><td colspan=6 style=" text-align: center;">'
      html += `<a href="${
        Api.baseUrl +
        '/' +
        store.state.restaurantActive.restaurantUrl +
        '/reservation?orderId=' +
        order._id
      }" style="display: inline-block;/* height: 45px; */padding: 10px 20px 10px 20px;border: 1px solid;border-radius: 10px;background-color: yellowgreen;color: white;text-decoration: none;">Reserve now</a>`
      html += '</td></td>'
    }

    html += '<tr><td colspan=6 style=" text-align: center;">'

    html += `<h2 style="margin-top: 0; margin-bottom: 0;">${store.state.restaurantActive.restaurantName}</h2>  `
    html += `<h4 style="margin-top: 0; margin-bottom: 0;">${store.state.restaurantActive.restaurantPhone} </h4> `
    html += `<h4 style="margin-top: 0; margin-bottom: 0;">${store.state.restaurantActive.restaurantAddress}  </h4>`

    if (store.state.restaurantActive.restaurantWeb)
      html += `<h4> <a 
          href="mailto:${store.state.restaurantActive.restaurantWeb}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;">
          ${store.state.restaurantActive.restaurantWeb}  </a></h4>`
    html += `</td>`
    html += `</tr>`
    html +=
      '<tr> <td colspan=6 align="center"  style="border-bottom: 1px solid grey;">'
    html += `<a 
        href="mailto:${store.state.restaurantActive.restaurantEmail}" 
        target="_blank" 
        rel="noopener noreferrer" 
        data-auth="NotApplicable" 
        style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/email-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantFacebok)
      html += `<a 
          href="${store.state.restaurantActive.restaurantFacebok}" 
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/Facebook-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantInstagram)
      html += `<a 
          href="${store.state.restaurantActive.restaurantInstagram}" 
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/instagram-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantTwitter)
      html += `<a 
          href="${store.state.restaurantActive.restaurantTwitter}" 
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Twitter-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantYoutube)
      html += `<a 
          href="${store.state.restaurantActive.restaurantYoutube}" 
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Youtube-icon.png"></img> </a>`

    html += '</td></tr>'
    html += `<tr><td  colspan="6">${Commons.getHtmlFooter({
      name: store.state.restaurantActive.restaurantName,
      phone: store.state.restaurantActive.restaurantPhone,
      email: store.state.restaurantActive.restaurantEmail
    })}</td></tr>`
    html += `</table></div></body></html>`

    return html
  },

  htmlToSendEvent(event) {
    let html = `
      <html>
        <head>
          <style>
            body {
              font-family: 'Arial', sans-serif;
              background-color: #f8f8f8;
              margin: 0;
              padding: 0;
            }
            .container {
              max-width: 600px;
              margin: 20px auto;
              background-color: ${event?.color};
              border: 1px solid #ddd;
              border-radius: 15px;
            }
            .header {
              text-align: center;
              padding: 20px;
              border-bottom: 2px solid #ddd;
            }
            .image {
              text-align: center;
            }
            .image img {
              width: 100%;
              height: auto;
              border-bottom: 2px solid #ddd;
            }
            .content {
              padding: 20px;
              text-align: center;
              color: #333;
            }
            .footer {
              text-align: center;
              padding: 15px;
              font-size: 14px;
              color: #777;
              border-top: 2px solid #ddd;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header" style="background-color: ${event.toggleTitle ? event.colorTitle : event.color};  border-top-left-radius: 15px; border-top-right-radius: 15px;">
              ${event.Title}
            </div>
            <div class="header" style="background-color: ${event.toggleSubtitle ? event.colorSubtitle : event.color};">
              ${event.Subtitle}
            </div>
            
            ${
              event.ImageUrl
                ? `
            <div class="image">
              <img src="${event.ImageUrl}" alt="Event Image">
            </div>`
                : ''
            }

            <div class="content" style="background-color:${event.toggleDescription ? event.colorDescription : event.color}">
              ${event.Description}  <!-- No se envuelve en etiquetas -->
            </div>
            
            <div class="footer">
              <p>${store.state.restaurantActive.restaurantName}</p>
            </div>
          </div>
        </body>
      </html>
    `
    return html
  },

  htmlToSendOrderSMS(order) {
    const { CustomerName, Total, OrderType, _id, Payment } = order;
    const { restaurantActive } = store.state;
    const restaurantName = restaurantActive.restaurantName;
    const restaurantUrl = restaurantActive.restaurantUrl;
    
    let smsContent = `Hi ${CustomerName}, `;
    smsContent += Payment 
      ? `Your order from ${restaurantName} with a total of ${this.getFormatPrice(Total)} has been paid.`
      : `We sent your order from ${restaurantName}. Your total is: ${this.getFormatPrice(Total)}.`;
    
    const urlOrder = `${Api.baseUrl}/${restaurantUrl}/reservation?orderId=${_id}`;
    const linkText = OrderType === "Ecom"
      ? "\n\nFollow the link to reserve now:"
      : "\n\nFollow the link to view your order:";
    
    const fullMessage = `${smsContent}${linkText} ${urlOrder}`;
  
    console.log(fullMessage);
    return fullMessage;
  },
  

  htmlToSendQuoteSMS(order) {
    let smsContent

    if (order.isInvoice) {
      const isPaid = order.Payment
      if (isPaid) {
        smsContent = `Hi ${order.CustomerName} \nYour invoice number ${order.QouteInvoice.number} from ${store.state.restaurantActive.restaurantName} with total ${this.getFormatPrice(order.Total - (order.Discount || 0) - (order.Deposit || 0))} has been paid\n\nFollow the link to view your invoice:`
      } else {
        smsContent = `Hi ${order.CustomerName} \nWe sent your invoice number ${order.QouteInvoice.number} from ${store.state.restaurantActive.restaurantName} \nYour total is: ${this.getFormatPrice(order.Total - (order.Discount || 0) - (order.Deposit || 0))}\n\nFollow the link to make your payment:`
      }
    } else {
      smsContent = `Hi ${order.CustomerName} \nWe sent your quote from ${store.state.restaurantActive.restaurantName} \nThe total is: ${this.getFormatPrice(order.Total - (order.Discount || 0) - (order.Deposit || 0))}\n\nFollow the link to view the quote:`
    }

    const truncatedContent = smsContent.substring(0, 160)

    if (order.isInvoice) {
      const urlInvoice = `${Api.baseUrl}/?rid=${store.state.restaurantActive.restaurantId}&payinvoice=${order._id}`
      console.log(`${truncatedContent} \n${urlInvoice}`)
      return `${truncatedContent} \n${urlInvoice}`
    }

    if (order.isQuote && !order.isInvoice) {
      const urlQuote = `${Api.baseUrl}/?rid=${store.state.restaurantActive.restaurantId}&acceptquote=${order._id}`
      console.log(`${truncatedContent} \n${urlQuote}`)
      return `${truncatedContent} \n${urlQuote}` // Aquí se añadió el "return"
    }
  },

  htmlToPickupRequestSMS(asset){
    let smsContent;

    const isPaid = asset.Payment;
    if (isPaid) {
      smsContent = `Hi ${asset.Name} \nYour asset pickup number ${asset.Number} from ${store.state.restaurantActive.restaurantName} with total ${this.getFormatPrice(asset?.Payment[0]?.total)} has been paid\n\nFollow the link to view your asset pickup:`;
    }else{
      smsContent = `Hi ${asset.CustomerName} \nWe sent your asset pickup number ${asset.Number} from ${store.state.restaurantActive.restaurantName} \nYour total is: ${this.getFormatPrice(store.backConfig.setting.DepositAmount)}\n\nFollow the link to make your payment:`;
    }

    const truncatedContent = smsContent.substring(0, 160);

    const urlAsset = `${Api.baseUrl}/?rid=${store.state.backConfig.restaurant._id}&shareasset=${asset._id}`;
    console.log(`${truncatedContent} \n${urlAsset}`)
    return `${truncatedContent} \n${urlAsset}`;
  },
  
  //Remove html tags and limit message to 160 characters
  htmlToSendEventSMS(event) {
    const smsContent = `${event.Title} \n${event.Subtitle} \n${event.Description}`

    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = smsContent

    const plainText = tempDiv.textContent || tempDiv.innerText || ''

    const truncatedContent = plainText.substring(0, 160)

    return truncatedContent
  },

  htmlToCustomer(customer, print = false) {
    let html = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 12px;
              color: #333;
              margin: 20px;
            }
            .container {
              width: 100%;
              max-width: 1000px;
              margin: 0 auto;
              padding: 20px;
              border: 1px solid #ddd;
            }
            .header {
              text-align: center;
              margin-bottom: 20px;
            }
            .header img {
              max-width: 150px;
              margin-bottom: 10px;
            }
            h1 {
              margin: 0;
              font-size: 24px;
              text-transform: uppercase;
            }
            .info {
              margin-bottom: 20px;
              border: 1px solid #ddd;
              padding: 10px;
              background-color: #f9f9f9;
              display: flex;
              text-align: left;
              flex-wrap: wrap; 
              gap: 10px;
            }
            .info h4 {
              flex: 1 0 45%; 
              margin: 5px 0;
            }
            .info strong {
              display: inline-block;
            }
            .note {
              margin-top: 20px;
              padding: 10px;
              background-color: #f4f4f4;
              border-left: 4px solid #333;
            }
            .company-columns {
              display: grid;
              grid-template-columns: repeat(2, 1fr); /* Dos columnas */
              gap: 20px;
            }
            .company-columns > div {
              display: flex;
              flex-direction: column;
            }
            .social-media-columns {
              display: grid;
              grid-template-columns: repeat(2, 1fr); /* Dos columnas */
              gap: 20px;
            }
              .marketing-consent {
              display: flex;
              gap: 1rem; 
            }

            .marketing-consent h4 {
              text-align: left;
              margin: 0; 
            }
            .social-media-columns > div {
              display: flex;
              flex-direction: column;
            }
            .footer {
              text-align: center;
              margin-top: 40px;
              font-size: 10px;
              color: #888;
            }
            .footer p {
              margin: 0;
            }
            @media (max-width: 768px) {
              .social-media-columns {
                grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
              }
            }
            @media (max-width: 600px) {
              .info h4 {
                flex: 1 0 100%; /* En pantallas pequeñas, cada h4 ocupa el 100% del ancho */
              }
            }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <h2>Customer Information</h2>
            </div>
            
            <div class="info">
              ${customer?.Name ? `
                <h4><strong>${i18n.t('frontend.order.client')}:</strong> ${customer.Name}</h4>
              ` : ""}
              ${customer?.EmailAddress ? `
                <h4><strong>${i18n.t('frontend.orderType.email')}:</strong> ${customer.EmailAddress}</h4>
              ` : ""}
              ${customer?.Phone ? `
                <h4><strong>${i18n.t('frontend.orderType.phone')}:</strong> ${customer.Phone}</h4>
              ` : ""}
              ${customer?.customerTypeSelect ? `
                <h4><strong>${i18n.t('frontend.orderType.customerType')}:</strong> ${customer.customerTypeSelect}</h4>
              ` : ""}
              ${customer?.customerStatusSelect ? `
                <h4><strong>${i18n.t('backoffice.form.titles.customerStatus')}:</strong> ${customer.customerStatusSelect}</h4>
              ` : ""}
              ${customer?.gender?.label ? `
                <h4><strong>${i18n.t('backoffice.form.titles.gender')}:</strong> ${customer.gender.label}</h4>
              ` : ""}
              ${customer?.lastContacted || customer?.notesLastContacted ? `
                <h4>
                  ${customer?.lastContacted ? `<div><strong>${i18n.t('frontend.orderType.lastContacted')}:</strong> ${customer.lastContacted}</div>` : ""}
                  ${customer?.notesLastContacted ? `<div style="color:grey;"><strong>${i18n.t('frontend.order.notes')}:</strong> ${customer.notesLastContacted}</div>` : ""}
                </h4>
              ` : ""}
              ${customer?.nextFollowUp || customer?.notesNextFollowUp ? `
                <h4>
                  ${customer?.nextFollowUp ? `<div><strong>${i18n.t('frontend.orderType.nextFollowUp')}:</strong> ${customer.nextFollowUp}</div>` : ""}
                  ${customer?.notesNextFollowUp ? `<div style="color:grey;"><strong>${i18n.t('frontend.order.notes')}:</strong> ${customer.notesNextFollowUp}</div>` : ""}
                </h4>
              ` : ""}
              ${customer?.preferredContactTime || customer?.notesContactToTime ? `
                <h4>
                  ${customer?.preferredContactTime ? `<div><strong>${i18n.t('frontend.orderType.preferredContactTime')}:</strong> ${customer.preferredContactTime}</div>` : ""}
                  ${customer?.notesContactToTime ? `<div style="color:grey;"><strong>${i18n.t('frontend.order.notes')}:</strong> ${customer.notesContactToTime}</div>` : ""}
                </h4>
              ` : ""}
              ${customer?.preferredContactToTime ? `
                <h4><strong>${i18n.t('frontend.orderType.preferredContactToTime')}:</strong> ${customer.preferredContactToTime}</h4>
              ` : ""}
              ${customer?.tags ? `
                <h4><strong>${i18n.t('backoffice.form.fields.tags')}:</strong> ${customer.tags}</h4>
              ` : ""}
            </div>
            
            <h4><strong>${i18n.t('frontend.order.notes')}:</strong> ${customer?.notes || 'No notes available.'}</h4>

            <div class="note">
              <h3>${i18n.t('backoffice.form.titles.preferredContact')}:</h3>
              <div class="marketing-consent">
                <h4><strong>Email:</strong> ${customer?.MarketingConsent?.Email ? 'Yes' : 'No'}</h4>
                <h4><strong>Phone:</strong> ${customer?.MarketingConsent?.Phone ? 'Yes' : 'No'}</h4>
                <h4><strong>SMS:</strong> ${customer?.MarketingConsent?.sms ? 'Yes' : 'No'}</h4>
              </div>
            </div>

            <div class="note">
              <h3>Company Details:</h3>
              <div class="company-columns">
                <div>
                  ${customer?.Company?.jobTitle ? `
                    <h4 style="text-align: left;"><strong>${i18n.t('backoffice.form.fields.companyTitle')}:</strong> ${customer.Company.jobTitle}</h4>
                  ` : ""}
                  ${customer?.Company?.name ? `
                    <h4 style="text-align: left;"><strong>${i18n.t('backoffice.form.fields.companyName')}:</strong> ${customer.Company.name}</h4>
                  ` : ""}
                  ${customer?.Company?.email ? `
                    <h4 style="text-align: left;"><strong>${i18n.t('backoffice.form.fields.companyEmail')}:</strong> ${customer.Company.email}</h4>
                  ` : ""}
                </div>
                <div>
                  ${customer?.Company?.phone ? `
                    <h4 style="text-align: left;"><strong>${i18n.t('backoffice.form.fields.companyPhone')}:</strong> ${customer.Company.phone}</h4>
                  ` : ""}
                  ${customer?.Company?.companyWebsite ? `
                    <h4 style="text-align: left;"><strong>${i18n.t('backoffice.form.fields.companyWebSite')}:</strong> <a href="${customer.Company.companyWebsite}" target="_blank">${customer.Company.companyWebsite}</a></h4>
                  ` : ""}
                  ${customer?.Company?.industrySelect ? `
                    <h4 style="text-align: left;"><strong>${i18n.t('backoffice.form.fields.companyIndustry')}:</strong> 
                      ${customer.Company.industrySelect === "Other" ? customer?.Company?.otherIndustry?? "" : customer?.Company?.industrySelect}
                    </h4>
                  ` : ""}
                  
                </div>
              </div>
            </div>

            <div class="note">
              <h3>Identification:</h3>
              <div class="info">
                ${customer?.identificationData?.idType ? `
                  <h4><strong>${i18n.t('backoffice.form.titles.idType')}:</strong> ${customer.identificationData.idType}</h4>
                ` : ""}
                
                ${customer?.identificationData?.idType === "DriverLicense" ? `
                  ${customer?.identificationData?.licenseName ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.nameLicense')}:</strong> ${customer.identificationData.licenseName}</h4>
                  ` : ""}
                  ${customer?.identificationData?.licenseNumber ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.licenseNumber')}:</strong> ${customer.identificationData.licenseNumber}</h4>
                  ` : ""}
                  ${customer?.identificationData?.licenseIssueDate ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.issueDate')}:</strong> ${customer.identificationData.licenseIssueDate}</h4>
                  ` : ""}
                  ${customer?.identificationData?.licenseExpirationDate ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.expirationDate')}:</strong> ${customer.identificationData.licenseExpirationDate}</h4>
                  ` : ""}
                  ${customer?.identificationData?.sex ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.sex')}:</strong> ${customer.identificationData.sex}</h4>
                  ` : ""}
                  ${customer?.identificationData?.dob ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.dateBirth')}:</strong> ${customer.identificationData.dob}</h4>
                  ` : ""}
                  ${customer?.identificationData?.country ? `
                    <h4><strong>${i18n.t('frontend.home.country')}:</strong> ${customer.identificationData.country}</h4>
                  ` : ""}
                  ${customer?.identificationData?.unit ? `
                    <h4>
                      <strong>${i18n.t('backoffice.form.titles.height')}:</strong>
                      ${customer?.identificationData?.primaryValue?? ""} 
                      ${customer.identificationData.unit === 'Meters' ? `${i18n.t('backoffice.form.titles.meters')}` : `${i18n.t('backoffice.form.titles.feet')}`}
                      ${customer.identificationData.secondaryValue ? ` / ${customer?.identificationData?.secondaryValue?? ""} ${customer.identificationData.unit === 'Meters' ? `${i18n.t('backoffice.form.titles.centimeters')}` : `${i18n.t('backoffice.form.titles.inches')}`}` : ''}
                    </h4>
                  ` : ""}

                ` : `
                  ${customer?.identificationData?.cedulaName ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.nameId')}:</strong> ${customer.identificationData.cedulaName}</h4>
                  ` : ""}
                  ${customer?.identificationData?.cedulaNumber ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.identificationNumber')}:</strong> ${customer.identificationData.cedulaNumber}</h4>
                  ` : ""}
                  ${customer?.identificationData?.cedulaExpirationDate ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.expirationDate')}:</strong> ${customer.identificationData.cedulaExpirationDate}</h4>
                  ` : ""}
                  ${customer?.identificationData?.nationality ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.nationality')}:</strong> ${customer.identificationData.nationality}</h4>
                  ` : ""}
                  ${customer?.identificationData?.maritalStatus ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.martialStatus')}:</strong> ${customer.identificationData.maritalStatus}</h4>
                  ` : ""}
                  ${customer?.identificationData?.country ? `
                    <h4><strong>${i18n.t('frontend.home.country')}:</strong> ${customer.identificationData.country}</h4>
                  ` : ""}
                  ${customer?.identificationData?.height ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.height')}:</strong> ${customer.identificationData.height} ${customer.identificationData.unit}</h4>
                  ` : ""}
                  ${customer?.identificationData?.occupation ? `
                    <h4><strong>${i18n.t('backoffice.form.titles.occupation')}:</strong> ${customer.identificationData.occupation}</h4>
                  ` : ""}
                `}
              </div>
            </div>

            <div class="note">
              <h3>Social Media:</h3>
              <div class="social-media-columns">
                <div>
                  ${customer?.socialMedia?.socialMedia?.bluesky ? `
                    <h4 style="text-align: left;"><strong>Bluesky:</strong> <a href="${customer.socialMedia.socialMedia.bluesky}" target="_blank">${customer.socialMedia.socialMedia.bluesky}</a></h4>
                  ` : ""}
                  ${customer?.socialMedia?.socialMedia?.twitter ? `
                    <h4 style="text-align: left;"><strong>Twitter:</strong> <a href="${customer.socialMedia.socialMedia.twitter}" target="_blank">${customer.socialMedia.socialMedia.twitter}</a></h4>
                  ` : ""}
                  ${customer?.socialMedia?.socialMedia?.facebook ? `
                    <h4 style="text-align: left;"><strong>Facebook:</strong> <a href="${customer.socialMedia.socialMedia.facebook}" target="_blank">${customer.socialMedia.socialMedia.facebook}</a></h4>
                  ` : ""}
                  ${customer?.socialMedia?.socialMedia?.instagram ? `
                    <h4 style="text-align: left;"><strong>Instagram:</strong> <a href="${customer.socialMedia.socialMedia.instagram}" target="_blank">${customer.socialMedia.socialMedia.instagram}</a></h4>
                  ` : ""}
                  ${customer?.socialMedia?.socialMedia?.tiktok ? `
                    <h4 style="text-align: left;"><strong>TikTok:</strong> <a href="${customer.socialMedia.socialMedia.tiktok}" target="_blank">${customer.socialMedia.socialMedia.tiktok}</a></h4>
                  ` : ""}
                </div>
                <div>
                  ${customer?.socialMedia?.socialMedia?.youtube ? `
                    <h4 style="text-align: left;"><strong>YouTube:</strong> <a href="${customer.socialMedia.socialMedia.youtube}" target="_blank">${customer.socialMedia.socialMedia.youtube}</a></h4>
                  ` : ""}
                  ${customer?.socialMedia?.socialMedia?.linkedin ? `
                    <h4 style="text-align: left;"><strong>LinkedIn:</strong> <a href="${customer.socialMedia.socialMedia.linkedin}" target="_blank">${customer.socialMedia.socialMedia.linkedin}</a></h4>
                  ` : ""}
                  ${customer?.socialMedia?.socialMedia?.whatsapp ? `
                    <h4 style="text-align: left;"><strong>WhatsApp:</strong> <a href="${customer.socialMedia.socialMedia.whatsapp}" target="_blank">${customer.socialMedia.socialMedia.whatsapp}</a></h4>
                  ` : ""}
                  ${customer?.socialMedia?.socialMedia?.snapchat ? `
                    <h4 style="text-align: left;"><strong>Snapchat:</strong> <a href="${customer.socialMedia.socialMedia.snapchat}" target="_blank">${customer.socialMedia.socialMedia.snapchat}</a></h4>
                  ` : ""}
                </div>
              </div>
            </div>

  
            <div class="footer">
              <h4>Thank you for being our customer!</h4>
            </div>
          </div>
        </body>
      </html>
    `;
    
    return html;
  },
  

  htmlToPickupRequest(asset, print = false) {
    let html = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 12px;
              color: #333;
              margin: 20px;
            }
            .container {
              width: 100%;
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
              border: 1px solid #ddd;
            }
            .header {
              text-align: center;
              margin-bottom: 20px;
            }
            .header img {
              max-width: 150px;
              margin-bottom: 10px;
            }
            h1 {
              margin: 0;
              font-size: 24px;
              text-transform: uppercase;
            }
            .info {
              margin-bottom: 20px;
              border: 1px solid #ddd;
              padding: 10px;
              background-color: #f9f9f9;
              text-align: left;
            }
            .info p {
              margin: 5px 0;
            }
            .info strong {
              display: inline-block;
              width: 120px;
            }
            .note {
              margin-top: 20px;
              padding: 10px;
              background-color: #f4f4f4;
              border-left: 4px solid #333;
            }
            .footer {
              text-align: center;
              margin-top: 40px;
              font-size: 10px;
              color: #888;
            }
            .footer p {
              margin: 0;
            }
            .content-item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
            }
            .content-item p {
              margin: 0;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="header">
              <h2>Pickup Request</h2>
              <h3>${store.state.restaurantActive.restaurantName}</h3>
            </div>
            
            <div class="info">
              <p><strong>Name:</strong> ${asset.Name}</p>
              <p><strong>Email:</strong> ${asset.Email}</p>
              <p><strong>Phone No:</strong> ${asset.PhoneNo}</p>
              <p><strong>Date:</strong> ${asset.PickupDate}</p>
              <p><strong>Address 1:</strong> ${asset.Address1}</p>
              <p><strong>Address 2:</strong> ${asset.Address2}</p>
              <p><strong>City:</strong> ${asset.City}</p>
              <p><strong>State:</strong> ${asset.State}</p>
              <p><strong>Zip Code:</strong> ${asset.ZipCodeFlag}</p>
              <p><strong>Country:</strong> ${asset.Country}</p>
              <p><strong>Pickup Date:</strong> ${asset.PickupDate}</p>
              <p><strong>Pickup Time:</strong> ${asset.PickupTime}</p>
              <p><strong>Note:</strong> ${asset.Note}</p>
            </div>

            ${
              asset.ImageUrl
                ? `
            <div class="image">
              <img src="${asset.ImageUrl}" alt="Pickup Image" style="max-width: 100%; height: auto;">
            </div>
            `
                : ''
            }
  
            <div class="note">
              <strong>Additional Notes:</strong>
              <p>${asset.Note ? asset.Note : 'No additional notes provided.'}</p>
            </div>
  
            <div class="footer">
              <p>Thank you for using our service!</p>
            </div>
          </div>
        </body>
      </html>
    `

    return html
  },

  htmlToSendQuote(order, print = false) {
    // var date = moment.tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');
    // var dateExpirationQuote = moment.tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');
    let date = DateTime.local().toFormat('MM/dd/yyyy HH:mm:ss')
    let dateExpirationQuote = DateTime.local().toFormat('MM/dd/yyyy HH:mm:ss')

    // if(order.Date)
    //   date = moment.tz(order.Date, moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');
    // console.log(order);
    if (order.Date)
      date = DateTime.fromISO(order.Date).toFormat('MM/dd/yyyy HH:mm:ss')
    // if(order.QouteInvoice?.expirationQuote)
    //   dateExpirationQuote = moment.tz(order.QouteInvoice?.expirationQuote, moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');
    if (order.QouteInvoice?.expirationQuote)
      dateExpirationQuote = DateTime.fromISO(
        order.QouteInvoice?.expirationQuote
      ).toFormat('MM/dd/yyyy HH:mm:ss')
    // if(order.QouteInvoice?.expirationInvoice)
    //   dateExpirationQuote = moment.tz(order.QouteInvoice?.expirationInvoice, moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss');
    if (order.QouteInvoice?.expirationInvoice)
      dateExpirationQuote = DateTime.fromISO(
        order.QouteInvoice?.expirationInvoice
      ).toFormat('MM/dd/yyyy HH:mm:ss')

    let urlCurbside = ''
    let orderInfo = ''
    if (order.OrderType == 'Delivery') orderInfo = order.AddressToDeliver
    // if(order.OrderType == 'PickUp')
    //     orderInfo = moment.tz(order.DateToPick, moment.tz.guess()).format('MM-DD-YYYY') + ' |  ' + moment.tz(order.HourToPick, moment.tz.guess()).format('hh:mm A')
    if (order.OrderType === 'PickUp') {
      const dateToPick = DateTime.fromISO(order.DateToPick, {
        zone: 'UTC'
      }).toFormat('MM-dd-yyyy')
      const hourToPick = DateTime.fromISO(order.HourToPick, {
        zone: 'UTC'
      }).toFormat('hh:mm a')
      orderInfo = `${dateToPick} | ${hourToPick}`
    }
    if (order.OrderType == 'On Table') orderInfo = order.tableService

    if (order.OrderType === 'Curbside') {
      const dateToPick = DateTime.fromISO(order.DateToPick, {
        zone: 'UTC'
      }).toFormat('MM-dd-yyyy')
      const hourToPick = DateTime.fromISO(order.HourToPick, {
        zone: 'UTC'
      }).toFormat('hh:mm a')
      orderInfo = `${dateToPick} | ${hourToPick}`

      if (order._id) {
        urlCurbside = `${Api.baseUrl}/?rid=${store.state.restaurantActive.restaurantId}&curbside=${order._id}`
      }
    }

    var html = ' <html><head>'
    html +=
      '<style> .progressBar { width: 100%;  border-bottom: 1px solid black;display: list-item;list-style: unset; padding: 0}'
    html +=
      '.progressBar li {list-style-type: none; float: left; position: relative; text-align: center; margin:0}'
    html +=
      '.progressBar li .before {content: " "; line-height: 30px; border-radius: 50%; width: 30px; height: 30px; border: 1px solid #ddd;'
    html +=
      'display: block;text-align: center;margin: 0 auto 10px;background-color: white}'
    html +=
      '.progressBar li .after { content: "";position: absolute;width: 100%;height: 4px;background-color: #ddd;top: 15px;left: -50%;z-index: -1;}'
    html +=
      '.progressBar li .one .after {content: none;}.progressBar li.active {color: black;}'
    html +=
      '.progressBar li.active .before { border-color: #63ee68; background-color: #63ee68}.progressBar .active:after {background-color: #4ca44f;} </style>'
    if (order.isInvoice) {
      html += `<title>Invoice #${order.QouteInvoice.number} - ${store.state.restaurantActive.restaurantName}</title>`
    } else if (order.isQuote) {
      html += `<title>Quote #${order.QouteInvoice.number} - ${store.state.restaurantActive.restaurantName}</title>`
    }
    html += '</head><body><div >'
    html += '<table  align=center style="width: 95%;">'
    html += '<tr><td colspan=7 style="text-align: center;">'
    html += `<h2 style="margin-top: 0; margin-bottom: 0;">${store.state.restaurantActive.restaurantName}</h2>  `
    html += `<img src="${store.state.restaurantActive.restaurantLogo}" style="max-width: 100px;"></img> `
    if (order.isInvoice) {
      html += `<h2 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.invoice'
      )}</h2>`
    } else if (order.isQuote) {
      html += `<h2 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.quote'
      )}</h2>`
    }
    html += `</td>`
    html += `</tr>`
    html += '<tr><td colspan=7 style="text-align: left">'

    const amountLabel = order.isInvoice
      ? `Total ${i18n.t('frontend.orderType.invoice')}`
      : `Total ${i18n.t('frontend.orderType.quote')}`
    html += `<div style="margin-top: 0; margin-bottom: 0;"><h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
      'frontend.order.client'
    )}: ${
      order.CustomerName
    } <span style="float: right;">${amountLabel}: ${this.getFormatPrice(
      order.Total - (order.Discount || 0) - (order.Deposit || 0)
    )}</span></h4></div><hr>`
    html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
      'frontend.orderType.phone'
    )}: ${order.CustomerPhone} </h4><hr>`
    if (!order.CustomerProjectName == '') {
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">Project Name: ${order.CustomerProjectName} </h4>`
    }
    if (!order.CustomerAddress == '') {
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.address'
      )}: ${order.CustomerAddress} </h4>`
    }

    if (order.OrderType) {
      // html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t('frontend.order.orderFor')} ${this.allTypeOrder[order.OrderType]}: ${orderInfo} </h4>`;
    }
    if (order.State && !order.isQuote)
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.order.orderState'
      )}: ${this.allOrderStates[order.State]} </h4>`
    if (urlCurbside !== '') {
      html += `<h2 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.home.curbsideDetail'
      )}</h2>`
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.licencePlate'
      )}: ${order.LicencePlate} </h4>`
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.vehicleModel'
      )}: ${order.VehicleModel} </h4>`
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.orderType.vehicleColor'
      )}: ${order.VehicleColor} </h4>`
      html += `<a 
            href="${urlCurbside}"
            target="_blank" 
            rel="noopener noreferrer" 
            data-auth="NotApplicable" 
            >${i18n.t('frontend.tooltips.getToRestaurant')}</a><hr>`
    }
    if (order.Payment) {
      html += `<br> <h2> ${i18n.t('frontend.order.payment')}: </h2>`
      for (const payment of order.Payment) {
        if (payment.paymentInfo)
          html += ` <h4> 
               ${i18n.t(
                 'frontend.order.total'
               )}: <strong>  ${this.getFormatPrice(payment.total)} </strong>  |
               ${i18n.t('frontend.order.transId')}: <strong>  ${
                 payment.paymentInfo.transId
               } </strong>  |
               ${i18n.t('frontend.order.paymentMethod')}: <strong>  ${
                 payment.PaymentMethod
               } </strong> 
             </h4>`
      }
    }

    if (order.isQuote || order.isInvoice) {
      html += '<hr>'
      order?.QouteInvoice?.number
        ? (html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
            'frontend.order.invoiceNumber'
          )}: ${order.QouteInvoice.number} </h4><hr>`)
        : ''
      order?.QouteInvoice?.term?.name
        ? (html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
            'frontend.order.invoiceTerm'
          )}: ${order.QouteInvoice.term.name} </h4><hr>`)
        : ''
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
        'frontend.order.date'
      )}: ${date} </h4><hr>`
      if (dateExpirationQuote && !order.isInvoice)
        html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
          'frontend.order.dueDate'
        )}: ${dateExpirationQuote} </h4><hr>`

      // if(order.QouteInvoice.Shipping){
      //   order?.QouteInvoice?.Shipping?.date ? html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t('frontend.order.shippingDate')}: ${moment.tz(order.QouteInvoice.Shipping.date, moment.tz.guess()).format('MM-DD-YYYY')} </h4><hr>` : ''
      //   order?.QouteInvoice?.Shipping?.trackingNumber ? html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t('frontend.order.shippingTrackingNumber')}: ${order.QouteInvoice.Shipping.trackingNumber} </h4><hr>` : ''
      //   order?.QouteInvoice?.Shipping?.via ? html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t('frontend.order.shippingVia')}: ${order.QouteInvoice.Shipping.via} </h4><hr>` : ''
      //   order?.QouteInvoice?.Shipping?.to ? html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t('frontend.order.shippingTo')}: ${order.QouteInvoice.Shipping.to} </h4><hr>` : ''
      //   order?.QouteInvoice?.Shipping?.from ? html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t('frontend.order.shippingFrom')}: ${order.QouteInvoice.Shipping.from} </h4><hr>` : ''
      // }
      if (order.QouteInvoice.Shipping) {
        if (order?.QouteInvoice?.Shipping?.date) {
          html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
            'frontend.order.shippingDate'
          )}: ${moment
            .tz(order.QouteInvoice.Shipping.date, moment.tz.guess())
            .format('MM-DD-YYYY')} </h4><hr>`
        }
        if (order?.QouteInvoice?.Shipping?.trackingNumber) {
          html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
            'frontend.order.shippingTrackingNumber'
          )}: ${order.QouteInvoice.Shipping.trackingNumber} </h4><hr>`
        }
        if (order?.QouteInvoice?.Shipping?.via) {
          html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
            'frontend.order.shippingVia'
          )}: ${order.QouteInvoice.Shipping.via} </h4><hr>`
        }
        if (order?.QouteInvoice?.Shipping?.to) {
          html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
            'frontend.order.shippingTo'
          )}: ${order.QouteInvoice.Shipping.to} </h4><hr>`
        }
        if (order?.QouteInvoice?.Shipping?.from) {
          html += `<h4 style="margin-top: 0; margin-bottom: 0;">${i18n.t(
            'frontend.order.shippingFrom'
          )}: ${order.QouteInvoice.Shipping.from} </h4><hr>`
        }
      }
    }
    html += `</td></tr>`
    html += `<tr ><td colspan=6 style="text-align: left"><h2 style="margin-top: 0; margin-bottom: 0;"><strong>${i18n.t(
      'backoffice.menu.foodServices'
    )}</strong></h2></td></tr> <tr></tr>`
    // for(var i = 0; i<order.Products.length ; i++){
    //     html += `<tr style="text-align:left"><td  colspan=4 style="margin-top: 0; margin-bottom: 0;border-bottom: 1px solid #dbd1d1;" ><strong >${order.Products[i].Name} `
    //     if(order.Products[i].VariantSelected)
    //       if(order.Products[i].VariantSelected.name)
    //         html += `${order.Products[i].VariantSelected.name }`
    //     html += `</strong>` ;
    //     if(this.hasSomeNote(order.Products[i]))
    //         html +=`<p style="background: #f1f1004d; margin-top: 0; margin-bottom: 0;">${this.getIngredientNote(order.Products[i])} </br> ${order.Products[i].Note}</p> `;
    //     html +=`</td><td style="border-bottom: 1px solid #dbd1d1;" > <p >( ${order.Products[i].Cant} X ${this.getFormatPrice(order.Products[i].Price)} )</p> </td>`;
    //     html += `<td style="border-bottom: 1px solid #dbd1d1; text-align: center;"> <p > ${ this.getFormatPrice( order.Products[i].Price * order.Products[i].Cant )}</p> </td>`;
    //     html += `</tr>`;

    // }
    html += `<tr><td colspan=8 style="margin-top: 0; margin-bottom: 0;border-bottom: 1px solid #dbd1d1;" >`
    html += `
      <table style="border-collapse: collapse; width: 100%;border: 1px solid rgb(216, 216, 216);">
        <thead>
          <tr style="background-color: #f2f2f2; height:20px;border: 1px solid rgb(216, 216, 216);">
            <th style="width: 25%;height:30px; border: 1px solid rgb(216, 216, 216);">Item</th>
            <th style="width: 40%;height:30px; border: 1px solid rgb(216, 216, 216);">Description</th>
            <th style="width: 10%;height:30px; border: 1px solid rgb(216, 216, 216);">Qty</th>
            <th style="width: 10%;height:30px; border: 1px solid rgb(216, 216, 216);">Price</th>
            <th style="width: 15%;height:30px; border: 1px solid rgb(216, 216, 216);">Amount</th>
          </tr>
        </thead>
        <tbody>
          ${order.Products.map(
            (product) => `
            <tr style="border: 1px solid rgb(216, 216, 216);">
              <td style="border-bottom: 1px solid #ddd;height:30px ; border: 1px solid rgb(216, 216, 216);">${
                product.Name
              }</td>
              <td style="border-bottom: 1px solid #ddd;height:30px ; border: 1px solid rgb(216, 216, 216);">${
                // product.Description
                //   ? product.Description
                //   : product.ShortDescription || ""
                this.cleanHtml(
                  product.Description
                    ? product.Description
                    : product.ShortDescription || ''
                )
              }</td>
              <td style="border-bottom: 1px solid #ddd;height:30px ; border: 1px solid rgb(216, 216, 216);">${
                product.Cant
              }</td>
              <td style="border-bottom: 1px solid #ddd;height:30px ; border: 1px solid rgb(216, 216, 216);">${this.getFormatPrice(
                product.Price
              )}</td>
              <td style="border-bottom: 1px solid #ddd;height:30px ; border: 1px solid rgb(216, 216, 216);">$ ${
                product.Cant * product.Price
              }</td>
            </tr>`
          ).join('')}
        </tbody>
      </table>`
    html += `</td></tr>`
    if (order?.OtherCharges?.length > 0) {
      for (var g = 0; g < order.OtherCharges.length; g++) {
        html += `<tr ><td  colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>${
          order.OtherCharges[g].Name
        }%</strong></p></td><td ><p>${this.getFormatPrice(
          order.OtherCharges[g].Price
        )} </p> </td></tr>`
      }
    }
    if (order?.OtherChargesC?.length > 0) {
      for (var g = 0; g < order.OtherChargesC.length; g++) {
        html += `<tr ><td  colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>${
          order.OtherChargesC[g].Name
        }%</strong></p></td><td ><p>${this.getFormatPrice(
          order.OtherChargesC[g].Price
        )} </p> </td></tr>`
      }
    }
    html += `<tr><td colspan=6><p style="text-align:end; margin-right:14px;"><strong>${i18n.t(
      'frontend.order.subtotal'
    )}</strong></p></td> <td > <p > ${this.getFormatPrice(
      order.SubTotal
    )}</p></td></tr>`
    let taxSubtotal = 0
    for (var g = 0; g < order.Products.length; g++) {
      const prod = order.Products[g]
      if (prod?.isTaxable) {
        // console.log({prod})
        taxSubtotal += prod.Cant * prod.Price
      }
    }

    for (var g = 0; g < order.AllTaxes.length; g++) {
      // console.log({taxSubtotal})
      html += `<tr><td  colspan=6><p style="text-align:end; margin-right:14px;"><strong>${
        order.AllTaxes[g].Name
      } (${
        order.AllTaxes[g].Percentage
      }%) </strong></p></td> <td > <p >${this.getFormatPrice(
        (order.AllTaxes[g].Percentage * taxSubtotal) / 100
      )}</p> </td></tr>`
    }

    if (order.OrderType == 'Delivery')
      html += `<tr ><td colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>${i18n.t(
        'frontend.order.deliver'
      )}</strong></p></td><td  ><p > ${this.getFormatPrice(
        order.Shipping
      )}</p></td></tr>`
    // if (order.Tip)
    //   html += `<tr ><td  colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>${i18n.t(
    //     "frontend.order.tip"
    //   )} ${order.Tip}%</strong></p></td><td ><p>${this.getFormatPrice(
    //     (order.Tip * order.SubTotal) / 100
    //   )} </p> </td></tr>`;

    //show shipping
    html += `<tr ><td colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>Deposit</strong></p></td> <td > <p > ${this.getFormatPrice(
      order.Deposit
    )}</p></td></tr>`

    //show shipping
    html += `<tr ><td colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>${i18n.t(
      'frontend.order.shipping'
    )}</strong></p></td> <td > <p>${this.getFormatPrice(
      order.Shipping
    )}</p></td></tr>`

    //show discount
    html += `<tr ><td colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>${i18n.t(
      'frontend.reservation.discount'
    )}</strong></p></td> <td > <p > ${this.getFormatPrice(
      order.Discount
    )}</p></td></tr>`
    if (order.enabledCashDiscount) {
      // show credit card fee
      html += `<tr ><td colspan=6 ><p style="text-align:end; margin-right:14px;"><strong>${i18n.t(
        'frontend.order.creditCardFee'
      )} ${order.selectedCashDiscount} %</strong></p></td> <td > <p > ${this.getFormatPrice(
        order.creditCardFee
      )}</p></td></tr>`
    }

    html += `<tr><td colspan=6 style="border-bottom: 1px solid #dbd1d1;"><p style="text-align:end; margin-right:14px;"><strong>${i18n.t(
      'frontend.order.total'
    )}</strong></p></td> <td style="border-bottom: 1px solid #dbd1d1;"> <strong > ${this.getFormatPrice(
      order.Total - (order.Discount || 0) - (order.Deposit || 0)
    )}</strong> </td></tr>`

    if (order.Note)
      html += `<tr ><td style="width: 20%;border-bottom: 1px solid grey;"><h4 >${i18n.t(
        'frontend.order.notes'
      )}</h4></td><td colspan=6 style="width: 80%;border-bottom: 1px solid grey;" ><p >${
        order.Note
      }</p></td></tr>`
    if (!print) {
      html += '<tr><td colspan=7 style=" text-align: center;">'
      if (order.isInvoice && !print) {
        const urlInvoice = `${Api.baseUrl}/?rid=${store.state.restaurantActive.restaurantId}&payinvoice=${order._id}`
        const isPaid = order.Payment
        if (isPaid) {
          html += `<a 
            href="${urlInvoice}"
            target="_blank" 
            rel="noopener noreferrer" 
            data-auth="NotApplicable" 
            style="background-color: #4CAF50; border: none; color: white; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 25px; padding: 12px 24px;"
          > View Invoice </a>`
        } else {
          html += `<a 
            href="${urlInvoice}"
            target="_blank" 
            rel="noopener noreferrer" 
            data-auth="NotApplicable" 
            style="background-color: #4CAF50; border: none; color: white; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 25px; padding: 12px 24px;"
          > Pay Invoice </a>`
        }

        //   html += `<a
        //   href="${urlInvoice}"
        //   target="_blank"
        //   rel="noopener noreferrer"
        //   data-auth="NotApplicable"
        //   style="background-color: #4CAF50; border: none; color: white; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 25px; padding: 12px 24px;"
        // > Download </a>`;
        // html += `<a href="${urlInvoice}" download="company_invoice_${order.invoiceNumber}.pdf" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" style="...">Download</a>`;
      }
      if (order.isQuote && !order.isInvoice && !print) {
        const urlQuote = `${Api.baseUrl}/?rid=${store.state.restaurantActive.restaurantId}&acceptquote=${order._id}`
        // html += `<a
        // href="${urlQuote}"
        // target="_blank"
        // rel="noopener noreferrer"
        // data-auth="NotApplicable"
        // > Accept Quote </a> `
        html += `<a 
          href="${urlQuote}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="background-color: #3498db; border: none; color: white; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 25px; padding: 12px 24px;"
        > Accept Quote </a>`
      }
      html += `<h2 style="margin-bottom: 0;">${store.state.restaurantActive.restaurantName}</h2>  `
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${store.state.restaurantActive.restaurantPhone} </h4> `
      html += `<h4 style="margin-top: 0; margin-bottom: 0;">${store.state.restaurantActive.restaurantAddress}  </h4>`
      if (store.state.restaurantActive.restaurantWeb)
        html += `<h4><a 
          href="mailto:${store.state.restaurantActive.restaurantWeb}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;">
          ${store.state.restaurantActive.restaurantWeb} </a>  </h4>`
      html += `</td>`
      html += `</tr>`
      html +=
        '<tr> <td colspan=6 align="center"  style="border-bottom: 1px solid grey; text-align: center;">'
      html += `<a 
        href="mailto:${store.state.restaurantActive.restaurantEmail}"
        target="_blank" 
        rel="noopener noreferrer" 
        data-auth="NotApplicable" 
        style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/email-icon.png"></img> </a>`
      if (store.state.restaurantActive.restaurantFacebok)
        html += `<a 
          href="${store.state.restaurantActive.restaurantFacebok}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/Facebook-icon.png"></img> </a>`
      if (store.state.restaurantActive.restaurantInstagram)
        html += `<a  
          href="${store.state.restaurantActive.restaurantInstagram}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/instagram-icon.png"></img> </a>`
      if (store.state.restaurantActive.restaurantTwitter)
        html += `<a 
          href="${store.state.restaurantActive.restaurantTwitter}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Twitter-icon.png"></img> </a>`
      if (store.state.restaurantActive.restaurantYoutube)
        html += `<a 
          href="${store.state.restaurantActive.restaurantYoutube}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Youtube-icon.png"></img> </a>`

      html += '</td></tr>'
      html += `<tr><td colspan=6 style="text-align: center;">`
      html += `
      <div style="margin: 4px;">
        <a href="" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" style="text-decoration: none; color:#000; display: inline-block;"><h3>Download iMenuApps®</h3></a>
        <a href="https://play.google.com/store/apps/details?id=net.imenuapps.apps" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" style="border: none; color: black; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin-left: 7px; vertical-align: middle;"><img src="https://storagemenusuccess.blob.core.windows.net/logo/androidgreenlogo.png" width="30" alt="android png" /></a>
        <a href="https://apps.apple.com/us/app/imenuapps/id1574771438" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" style="border: none; color: black; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin-left: 7px; vertical-align: middle;"><img src="https://storagemenusuccess.blob.core.windows.net/logo/iosgreenbluelogo.png" width="30" alt="apple ios png"/></a>
      </div>`

      html += `</td></tr>`
      html += `<tr><td  colspan="6">${Commons.getHtmlFooter({
        name: store.state.restaurantActive.restaurantName,
        phone: store.state.restaurantActive.restaurantPhone,
        email: store.state.restaurantActive.restaurantEmail
      })}</td></tr>`
    }
    html += `</table></div></body></html>`

    return html
  },

  async sendOrderEmail(order) {
    const html = this.htmlToSendEmailOrder(order)
    var items = {
      email: order.CustomerEmail,
      mess: html,
      subject:
        i18n.t('frontend.order.invoice') +
        '-' +
        order.Payment[0].paymentInfo.transId +
        ' ' +
        store.state.restaurantActive.restaurantName
    }
    await Api.sendEmail(items)
    return
  },

  async sendInvoice(order, update = false) {
    const html = this.htmlToSendEmailOrder(order, true)
    const mss = update
      ? i18n.t('frontend.order.invoiceProposalUpdated') +
        ' - ' +
        store.state.restaurantActive.restaurantName
      : i18n.t('frontend.order.invoiceProposal') +
        ' - ' +
        store.state.restaurantActive.restaurantName
    var items = {
      email: order.CustomerEmail,
      mess: html,
      subject: mss
    }
    await Api.sendEmail(items)
    await this.fetchOrdersBackOffice()
    return
  },

  fetchOrdersBackOffice: async function () {
    const response = await Api.fetchAll('Order')
    if (response.status === 200) {
      store.state.backConfig.order = response.data
      const quote = response.data.filter(
        (order) => order.isInvoice || order.isQuote
      )
      store.state.backConfig.invoicesOrQuotes = quote
    }
  },

  getIngredientNote(car) {
    let str = i18n.t('frontend.home.withoutIngredients') + ': '
    let count = 0

    if (car.Ingredients)
      car.Ingredients.forEach((e) => {
        if (e.selected === 0) {
          str += e.name + '. '
          count++
        }
      })
    if (count > 0) return str

    return ''
  },

  hasSomeNote(car) {
    let flag = false
    if (car.Note) flag = true
    if (this.getIngredientNote(car) !== '') flag = true
    return flag
  },

  htmlSendReservation(reservation) {
    const allState = [
      i18n.t('frontend.reservation.state0'),
      i18n.t('frontend.reservation.state1'),
      i18n.t('frontend.reservation.state2'),
      i18n.t('frontend.reservation.state3'),
      i18n.t('frontend.reservation.state4'),
      i18n.t('frontend.reservation.state5'),
      i18n.t('frontend.reservation.state6')
    ]
    var html = ' <html><head>'
    html +=
      '<style> .progressBar { width: 100%;  border-bottom: 1px solid black;display: list-item;list-style: unset; padding: 0}'
    html +=
      '.progressBar li {list-style-type: none; float: left; position: relative; text-align: center; margin:0}'
    html +=
      '.progressBar li .before {content: " "; line-height: 30px; border-radius: 50%; width: 30px; height: 30px; border: 1px solid #ddd;'
    html +=
      'display: block;text-align: center;margin: 0 auto 10px;background-color: white}'
    html +=
      '.progressBar li .after { content: "";position: absolute;width: 100%;height: 4px;background-color: #ddd;top: 15px;left: -50%;z-index: -1;}'
    html +=
      '.progressBar li .one .after {content: none;}.progressBar li.active {color: black;}'
    html +=
      '.progressBar li.active .before { border-color: #63ee68; background-color: #63ee68}.progressBar .active:after {background-color: #4ca44f;} </style>'

    html += '</head><body><div >'
    html += '<table  align=center style="width: 90%;">'
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h2>${i18n.t('frontend.reservation.reservationDetail')}</h2>  `
    html += `</td>`
    html += `</tr>`
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h4>${store.state.restaurantActive.restaurantName}</h4>  `
    html += `<img src="${store.state.restaurantActive.restaurantLogo}" style="max-width: 100px;"></img> `
    html += `</td></tr>`
    html += '<tr><td colspan=6 >'
    if (reservation.CustomerName)
      html += `<h4> ${i18n.t('frontend.orderType.name')}: ${
        reservation.CustomerName
      }</h4>`
    if (reservation.CustomerEmail)
      html += ` <h4> ${i18n.t('frontend.orderType.email')}: ${
        reservation.CustomerEmail
      }</h4>`
    if (reservation.CustomerPhone)
      html += ` <h4> ${i18n.t('frontend.orderType.phone')}: ${
        reservation.CustomerPhone
      }</h4>`
    if (reservation.Capacity)
      html += ` <h4> ${i18n.t('frontend.reservation.peoples')}: ${
        reservation.Capacity
      }</h4>`

    // const hourOut = moment(this.hourToReserv).add(parseInt(reservation.ServiceTime), 'minutes');
    const newTime = DateTime.fromJSDate(
      new Date(reservation.reservationTimestamp)
    ).plus({ minutes: parseInt(reservation.ServiceTime) })
    const hourOut = Utils.get24HourStrFromTimestamp(
      newTime.ts,
      'en-US',
      reservation.TimeZone
    )

    if (reservation.reservationTimestamp)
      html += ` <h4> ${i18n.t(
        'frontend.reservation.reservationDate'
      )}: ${Utils.getDateStr(reservation.reservationTimestamp)}</h4>`
    if (reservation.reservationTimestamp)
      html += ` <h4> ${i18n.t(
        'frontend.reservation.reservationHour'
      )}: ${Utils.get24HourStrFromTimestamp(
        reservation.reservationTimestamp,
        'en-US',
        reservation.TimeZone
      )}  -   ${hourOut}</h4>`
    if (reservation.Note)
      html += ` <h4> ${i18n.t('frontend.order.notes')}: ${
        reservation.Note
      }</h4>`
    if (reservation.Reason)
      html += ` <h4> ${i18n.t('frontend.reservation.reason')}: ${
        reservation.Reason
      }</h4>`
    if (reservation.State)
      html += ` <h4> ${i18n.t('frontend.home.state')}: ${
        allState[reservation.State]
      }</h4>`
    if (reservation.QuotationPayment)
      html += `<h4> ${i18n.t(
        'frontend.reservation.deposit'
      )}: ${this.getFormatPrice(reservation.QuotationPayment)}</h4>`
    html += `</td></tr>`

    html += '<tr><td colspan=6 style=" text-align: center;">'
    html += `<h2>${store.state.restaurantActive.restaurantName}</h2>  `
    html += `<h4>${store.state.restaurantActive.restaurantPhone} </h4> `
    html += `<h4>${store.state.restaurantActive.restaurantAddress}  </h4>`
    if (store.state.restaurantActive.restaurantWeb)
      html += `<h4><a 
          href="mailto:${store.state.restaurantActive.restaurantWeb}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;">${store.state.restaurantActive.restaurantWeb}  </a></h4>`
    html += `</td>`
    html += `</tr>`
    html +=
      '<tr> <td colspan=6 align="center"  style="border-bottom: 1px solid grey;">'
    html += `<a 
      href="mailto:${store.state.restaurantActive.restaurantEmail}" target="_blank" 
      rel="noopener noreferrer" 
      data-auth="NotApplicable" 
      style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/email-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantFacebok)
      html += `<a 
          href="${store.state.restaurantActive.restaurantFacebok}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/Facebook-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantInstagram)
      html += `<a  
          href="${store.state.restaurantActive.restaurantInstagram}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/instagram-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantTwitter)
      html += `<a 
          href="${store.state.restaurantActive.restaurantTwitter}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Twitter-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantYoutube)
      html += `<a 
          href="${store.state.restaurantActive.restaurantYoutube}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Youtube-icon.png"></img> </a>`

    html += '</td></tr>'
    html += `<tr><td  colspan="6">${Commons.getHtmlFooter({
      name: store.state.restaurantActive.restaurantName,
      phone: store.state.restaurantActive.restaurantPhone,
      email: store.state.restaurantActive.restaurantEmail
    })}</td></tr>`
    html += `</table></div></body></html>`

    return html
  },

  htmlSendWorksheet(worksheet, staff) {
    var html = ' <html><head>'
    html +=
      '<style> .progressBar { width: 100%;  border-bottom: 1px solid black;display: list-item;list-style: unset; padding: 0}'
    html +=
      '.progressBar li {list-style-type: none; float: left; position: relative; text-align: center; margin:0}'
    html +=
      '.progressBar li .before {content: " "; line-height: 30px; border-radius: 50%; width: 30px; height: 30px; border: 1px solid #ddd;'
    html +=
      'display: block;text-align: center;margin: 0 auto 10px;background-color: white}'
    html +=
      '.progressBar li .after { content: "";position: absolute;width: 100%;height: 4px;background-color: #ddd;top: 15px;left: -50%;z-index: -1;}'
    html +=
      '.progressBar li .one .after {content: none;}.progressBar li.active {color: black;}'
    html +=
      '.progressBar li.active .before { border-color: #63ee68; background-color: #63ee68}.progressBar .active:after {background-color: #4ca44f;} </style>'

    html += '</head><body><div >'
    html += '<table  align=center style="width: 90%;">'
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h2>${i18n.t('backoffice.worksheetStatus.worksheetDetail')}</h2>  `
    html += `</td>`
    html += `</tr>`
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h4>${store.state.restaurantActive.restaurantName}</h4>  `
    html += `<img src="${store.state.restaurantActive.restaurantLogo}" style="max-width: 100px;"></img> `
    html += `</td></tr>`
    html += '<tr><td colspan=6 style="text-align: center;">'
    if (staff.FirstName)
      html += `<h4> ${i18n.t('frontend.orderType.name')}: ${staff.FirstName} ${
        staff.LastName
      }</h4>`
    if (staff.Email)
      html += ` <h4> ${i18n.t('frontend.orderType.email')}: ${staff.Email}</h4>`

    if (worksheet.WorkingDates.length) {
      for (const dd of worksheet.WorkingDates) {
        html += ` <h4> 
${i18n.t('frontend.credit.dateFrom')}: ${DateTime.fromJSDate(
          new Date(dd.StartDate)
        ).toFormat('MM/dd/yyyy HH:mm:ss')}         ${i18n.t(
          'frontend.credit.dateTo'
        )}: ${DateTime.fromJSDate(new Date(dd.EndDate)).toFormat(
          'MM/dd/yyyy HH:mm:ss'
        )}
</h4>`
      }
    }

    html += `</td></tr>`

    html += '<tr><td colspan=6 style=" text-align: center;">'
    html += `<h2>${store.state.restaurantActive.restaurantName}</h2>  `
    html += `<h4>${store.state.restaurantActive.restaurantPhone} </h4> `
    html += `<h4>${store.state.restaurantActive.restaurantAddress}  </h4>`
    if (store.state.restaurantActive.restaurantWeb)
      html += `<h4><a 
          href="mailto:${store.state.restaurantActive.restaurantWeb}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;">${store.state.restaurantActive.restaurantWeb}  </a></h4>`
    html += `</td>`
    html += `</tr>`
    html +=
      '<tr> <td colspan=6 align="center"  style="border-bottom: 1px solid grey;">'
    html += `<a 
      href="mailto:${store.state.restaurantActive.restaurantEmail}" target="_blank" 
      rel="noopener noreferrer" 
      data-auth="NotApplicable" 
      style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/email-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantFacebok)
      html += `<a 
          href="${store.state.restaurantActive.restaurantFacebok}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/Facebook-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantInstagram)
      html += `<a  
          href="${store.state.restaurantActive.restaurantInstagram}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/instagram-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantTwitter)
      html += `<a 
          href="${store.state.restaurantActive.restaurantTwitter}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Twitter-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantYoutube)
      html += `<a 
          href="${store.state.restaurantActive.restaurantYoutube}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Youtube-icon.png"></img> </a>`

    html += '</td></tr>'
    html += `<tr><td  colspan="6">${Commons.getHtmlFooter({
      name: store.state.restaurantActive.restaurantName,
      phone: store.state.restaurantActive.restaurantPhone,
      email: store.state.restaurantActive.restaurantEmail
    })}</td></tr>`
    html += `</table></div></body></html>`

    return html
  },

  htmlSendShifts(shifts) {
    var html = ' <html><head>'
    html +=
      '<style> .progressBar { width: 100%;  border-bottom: 1px solid black;display: list-item;list-style: unset; padding: 0}'
    html +=
      '.progressBar li {list-style-type: none; float: left; position: relative; text-align: center; margin:0}'
    html +=
      '.progressBar li .before {content: " "; line-height: 30px; border-radius: 50%; width: 30px; height: 30px; border: 1px solid #ddd;'
    html +=
      'display: block;text-align: center;margin: 0 auto 10px;background-color: white}'
    html +=
      '.progressBar li .after { content: "";position: absolute;width: 100%;height: 4px;background-color: #ddd;top: 15px;left: -50%;z-index: -1;}'
    html +=
      '.progressBar li .one .after {content: none;}.progressBar li.active {color: black;}'
    html +=
      '.progressBar li.active .before { border-color: #63ee68; background-color: #63ee68}.progressBar .active:after {background-color: #4ca44f;} </style>'

    html += '</head><body><div >'
    html += '<table  align=center style="width: 90%;">'
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h2>${i18n.t('backoffice.titles.timesheet')}</h2>  `
    html += `</td>`
    html += `</tr>`
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h4>${store.state.restaurantActive.restaurantName}</h4>  `
    html += `<img src="${store.state.restaurantActive.restaurantLogo}" style="max-width: 100px;"></img> `
    html += `</td></tr>`
    html += '<tr><td colspan=6 style="text-align: center;">'
    html +=
      '<table class="report-table"><thead><tr>\n' +
      '        <th style="width: 15%;">Clock In</th>\n' +
      '        <th style="width: 15%;">Clock Out</th>\n' +
      '        <th style="width: 10%;">Worked Hours</th>\n' +
      '      </tr></thead>\n' +
      '      <tbody>'
    for (const shift of shifts) {
      html += `<tr>
        <td>${DateTime.fromISO(shift.DateTime).toFormat(
          'MM/dd/yyyy HH:mm:ss'
        )}</td>
        <td>${
          shift.ClockOutDateTime
            ? DateTime.fromISO(shift.ClockOutDateTime).toFormat(
                'MM/dd/yyyy HH:mm:ss'
              )
            : 'N/A'
        }</td>
        <td>${shift.TimeDifference || 'N/A'}</td>
      </tr>`
    }
    html += `</tbody>
    </table>`
    html += `</td></tr>`

    html += '<tr><td colspan=6 style=" text-align: center;">'
    html += `<h2>${store.state.restaurantActive.restaurantName}</h2>  `
    html += `<h4>${store.state.restaurantActive.restaurantPhone} </h4> `
    html += `<h4>${store.state.restaurantActive.restaurantAddress}  </h4>`
    if (store.state.restaurantActive.restaurantWeb)
      html += `<h4><a 
          href="mailto:${store.state.restaurantActive.restaurantWeb}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;">${store.state.restaurantActive.restaurantWeb}  </a></h4>`
    html += `</td>`
    html += `</tr>`
    html +=
      '<tr> <td colspan=6 align="center"  style="border-bottom: 1px solid grey;">'
    html += `<a 
      href="mailto:${store.state.restaurantActive.restaurantEmail}" target="_blank" 
      rel="noopener noreferrer" 
      data-auth="NotApplicable" 
      style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/email-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantFacebok)
      html += `<a 
          href="${store.state.restaurantActive.restaurantFacebok}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/Facebook-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantInstagram)
      html += `<a  
          href="${store.state.restaurantActive.restaurantInstagram}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/instagram-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantTwitter)
      html += `<a 
          href="${store.state.restaurantActive.restaurantTwitter}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Twitter-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantYoutube)
      html += `<a 
          href="${store.state.restaurantActive.restaurantYoutube}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Youtube-icon.png"></img> </a>`

    html += '</td></tr>'
    html += `<tr><td  colspan="6">${Commons.getHtmlFooter({
      name: store.state.restaurantActive.restaurantName,
      phone: store.state.restaurantActive.restaurantPhone,
      email: store.state.restaurantActive.restaurantEmail
    })}</td></tr>`
    html += `</table></div></body></html>`

    return html
  },

  htmlSendWorksheetChangeReq(
    worksheet,
    staff,
    newWorkingDates,
    reason,
    isManager
  ) {
    var html = ' <html><head>'
    html +=
      '<style> .progressBar { width: 100%;  border-bottom: 1px solid black;display: list-item;list-style: unset; padding: 0}'
    html +=
      '.progressBar li {list-style-type: none; float: left; position: relative; text-align: center; margin:0}'
    html +=
      '.progressBar li .before {content: " "; line-height: 30px; border-radius: 50%; width: 30px; height: 30px; border: 1px solid #ddd;'
    html +=
      'display: block;text-align: center;margin: 0 auto 10px;background-color: white}'
    html +=
      '.progressBar li .after { content: "";position: absolute;width: 100%;height: 4px;background-color: #ddd;top: 15px;left: -50%;z-index: -1;}'
    html +=
      '.progressBar li .one .after {content: none;}.progressBar li.active {color: black;}'
    html +=
      '.progressBar li.active .before { border-color: #63ee68; background-color: #63ee68}.progressBar .active:after {background-color: #4ca44f;} </style>'

    html += '</head><body><div >'
    html += '<table  align=center style="width: 90%;">'
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h2>${i18n.t('backoffice.worksheetStatus.worksheetDetail')}</h2>  `
    html += `</td>`
    html += `</tr>`
    html += '<tr><td colspan=6 style="text-align: center;">'
    html += `<h4>${store.state.restaurantActive.restaurantName}</h4>  `
    html += `<img src="${store.state.restaurantActive.restaurantLogo}" style="max-width: 100px;"></img> `
    html += `</td></tr>`
    html += '<tr><td colspan=6 style="text-align: center;">'
    if (staff.FirstName)
      html += `<h4> ${i18n.t('frontend.orderType.name')}: ${staff.FirstName} ${
        staff.LastName
      }</h4>`
    if (staff.Email)
      html += ` <h4> ${i18n.t('frontend.orderType.email')}: ${staff.Email}</h4>`

    html += `</td></tr>`
    if (reason) {
      html += '<tr><td colspan=6 style="text-align: center;">'
      html += `<p> Reason: ${reason}</p>`
      html += `</td></tr>`
    }
    html += '<tr>'
    html += '<td colspan=3 style="text-align: center;">'
    html += '<h3>From</h3>'
    if (worksheet.WorkingDates.length) {
      for (const dd of worksheet.WorkingDates) {
        html += ` <h4> 
${i18n.t('frontend.credit.dateFrom')}: ${DateTime.fromJSDate(
          new Date(dd.StartDate)
        ).toFormat('MM/dd/yyyy HH:mm:ss')}
</h4>`
        html += ` <h4> 
${i18n.t('frontend.credit.dateTo')}: ${DateTime.fromJSDate(
          new Date(dd.EndDate)
        ).toFormat('MM/dd/yyyy HH:mm:ss')}
</h4>`
      }
    }
    html += `</td>`
    html += '<td colspan=3 style="text-align: center;">'
    html += '<h3>To</h3>'
    if (newWorkingDates.length) {
      for (const dd of newWorkingDates) {
        html += ` <h4> 
${i18n.t('frontend.credit.dateFrom')}: ${DateTime.fromJSDate(
          new Date(dd.StartDate)
        ).toFormat('MM/dd/yyyy HH:mm:ss')} 
</h4>`
        html += ` <h4> ${i18n.t(
          'frontend.credit.dateTo'
        )}: ${DateTime.fromJSDate(new Date(dd.EndDate)).toFormat(
          'MM/dd/yyyy HH:mm:ss'
        )}
</h4>`
      }
    }
    html += `</td>`

    html += `</tr>`

    if (isManager) {
      const urlInvoice = `${Api.baseUrl}/?rid=${
        store.state.restaurantActive.restaurantId
      }&approveWorksheet=${true}&sheetId=${worksheet._id}`
      html += `<tr><td colspan=6 style=" text-align: center;">'<a 
          href="${urlInvoice}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="background-color: #4CAF50; border: none; color: white; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 25px; padding: 12px 24px;"
        > Approve </a></td></tr>`
    }
    html += '<tr><td colspan=6 style=" text-align: center;">'
    html += `<h2>${store.state.restaurantActive.restaurantName}</h2>  `
    html += `<h4>${store.state.restaurantActive.restaurantPhone} </h4> `
    html += `<h4>${store.state.restaurantActive.restaurantAddress}  </h4>`
    if (store.state.restaurantActive.restaurantWeb)
      html += `<h4><a 
          href="mailto:${store.state.restaurantActive.restaurantWeb}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;">${store.state.restaurantActive.restaurantWeb}  </a></h4>`
    html += `</td>`
    html += `</tr>`
    html +=
      '<tr> <td colspan=6 align="center"  style="border-bottom: 1px solid grey;">'
    html += `<a 
      href="mailto:${store.state.restaurantActive.restaurantEmail}" target="_blank" 
      rel="noopener noreferrer" 
      data-auth="NotApplicable" 
      style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/email-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantFacebok)
      html += `<a 
          href="${store.state.restaurantActive.restaurantFacebok}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;" src="https://storagemenusuccess.blob.core.windows.net/logo/Facebook-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantInstagram)
      html += `<a  
          href="${store.state.restaurantActive.restaurantInstagram}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/instagram-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantTwitter)
      html += `<a 
          href="${store.state.restaurantActive.restaurantTwitter}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Twitter-icon.png"></img> </a>`
    if (store.state.restaurantActive.restaurantYoutube)
      html += `<a 
          href="${store.state.restaurantActive.restaurantYoutube}"
          target="_blank" 
          rel="noopener noreferrer" 
          data-auth="NotApplicable" 
          style="margin: 0 10px;"><img style="width: 32px;"  src="https://storagemenusuccess.blob.core.windows.net/logo/Youtube-icon.png"></img> </a>`

    html += '</td></tr>'
    html += `<tr><td  colspan="6">${Commons.getHtmlFooter({
      name: store.state.restaurantActive.restaurantName,
      phone: store.state.restaurantActive.restaurantPhone,
      email: store.state.restaurantActive.restaurantEmail
    })}</td></tr>`
    html += `</table></div></body></html>`

    return html
  },

  cleanHtml(html) {
    let tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    let images = tempDiv.querySelectorAll('img')
    images.forEach((img) => img.remove())
    return tempDiv.innerHTML
  },
  async alertSubscription(email, name, phone) {
    const index = store.state.subscribers.findIndex((s) => s.Email === email)
    var mss = ''
    var flag = false

    if (index === -1) {
      mss = i18n.t('frontend.createNew.reciveSubscription')
      flag = true
    } else {
      if (store.state.subscribers[index].State === false) {
        mss = i18n.t('frontend.createNew.activateSubscription')
        flag = true
      }
    }
    if (flag) {
      const toast = document.createElement('ion-toast')
      toast.message = mss + ' ' + store.state.restaurantActive.restaurantName
      toast.position = 'botton'
      toast.color = 'dark'
      toast.duration = 10000
      toast.buttons = [
        {
          text: 'Ok',
          role: 'acept',
          handler: async () => {
            if (index === -1) {
              toast.dismiss()
              const data = {
                Email: email,
                Phone: phone,
                Name: name
              }
              const response = await Api.postIn('subscriber', data)
              if (response.status === 200) {
                await this.getSubscribers()
              }
            } else {
              toast.dismiss()
              const data = store.state.subscribers[index]
              data.State = true
              const response = await Api.putIn('subscriber', data)
              if (response.status === 200) {
                await this.getSubscribers()
              }
            }
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {}
        }
      ]

      document.body.appendChild(toast)
      return toast.present()
    }
  },

  async getStaff() {
    const response = await Api.fetchAll('Staff')
    if (response.status === 200) {
      const users = response.data.filter(
        (usr) => usr.IsSupport == false || !usr.IsSupport
      )
      store.commit('setAllStaff', users)
    }
  },

  //********************************** BACK OFFICE ********************************************* //

  async getCompleteRestaurant() {
    const response = await Api.findCompleteRestaurantObject()
    if (response) {
      const allCustomer = JSON.parse(JSON.stringify(response.allCustomer))
      const tax = JSON.parse(JSON.stringify(response.tax))
      response.allCustomer = allCustomer
      response.tax = tax
      const invoicesOrQuotes = response.order.filter(
        (order) => order.isInvoice || order.isQuote
      )
      response.invoicesOrQuotes = invoicesOrQuotes
      //console.log('JQD', "getCompleteRestaurant", response.data);
      store.commit('setBackConfig', JSON.parse(JSON.stringify(response)))
    }
  },

  async backOfficeLogin(staff) {
    await Commons.changeRestaurant(staff.RestaurantId)
    store.commit('setUser', staff)
    payAuthorizeNet.setClerkId(staff.ServerId)
    let value = false
    if (staff.Permission) {
      if (staff.Permission.canCreateHouseAccount) value = true
      store.commit('setRoles', [staff.Permission])
    } else {
      let roles = []
      for (const rol of staff.Roles) {
        const obj = store.state.backConfig.rol.find((r) => r._id === rol)
        if (obj) {
          roles.push(obj)
          if (obj.canCreateHouseAccount) value = true
        }
      }
      store.commit('setRoles', roles)
    }
    store.commit('setStaffHouseAccount', value)
  },

  /******************* PAYMENTS COMMONS METHODS ******************************* */

  closeTicket() {},

  recivePayment: async function (
    res,
    Total,
    Tip,
    isCatering = false,
    isTicket = false,
    closeTicket = false
  ) {
    const order = store.state.order
    //console.log("Total recibido en recivePayment:", Total);

    if (isCatering) return this.recivePaymentCatering(res, order)
    if (isTicket) return this.recivePaymentTicket(res, order)
    try {
      order.Total = Number(Total)
      order.Tip = Number(Tip)
      //store.commit('setOrder', order);
      order.Payment = [
        {
          state: 1,
          total: Total,
          paymentInfo: res,
          PaymentMethod: res.method + ' ' + res.accountType
        }
      ]
      if (!closeTicket) order.State = 1
      let response = null
      if (!order._id) response = await Api.postIn('Order', order)
      else response = await Api.putIn('Order', order)

      if (response.status === 200) {
        store.commit('setOrder', response.data)
        if (res.method === 'Credit')
          this.updateCustomerCredit(
            parseFloat(Total),
            'Order',
            response.data._id
          )
        else if (res.method === 'HouseAccount') {
          const houseAccount = {
            ServerName: order.StaffName,
            CustomerName: order.CustomerName,
            Amount: Total,
            Model: 'Order',
            ModelId: response.data._id
          }
          await Api.postIn('houseAccount', houseAccount)
        } else {
          const paymentEntry = {
            Method: res.method,
            Payment: Total,
            InvoiceNumber: res.transId,
            ModelId: response.data._id,
            ModelFrom: 'Order',
            StaffName: this.order.StaffName
          }
          await Api.postIn('allpayments', paymentEntry)
        }

        return { to: '', value: response.data }
      }
    } catch (error) {
      error
    }
  },

  recivePaymentCatering: async function (res, order) {
    try {
      let quo =
        store.configuration.percentPayForQuotation > 0 &&
        store.configuration.payForQuotation
          ? store.configuration.percentPayForQuotation
          : 0

      order.QuotationPayment = quo.toFixed(2)
      order.QuotationPaymentDetail = res

      const response = await Api.postIn('Order', order)
      if (response.status === 200 && response.statusText === 'OK') {
        store.commit('setOrder', response.data)
        if (res.method === 'Credit')
          Commons.updateCustomerCredit(
            parseFloat(res.total),
            'Order',
            response.data._id
          )
        else if (res.method === 'HouseAccount') {
          const houseAccount = {
            ServerName: order.StaffName,
            CustomerName: order.CustomerName,
            Amount: res.total,
            Model: 'Catering',
            ModelId: response.data._id
          }
          await Api.postIn('houseAccount', houseAccount)
        } else {
          const paymentEntry = {
            Method: res.method,
            Payment: res.total,
            InvoiceNumber: res.transId,
            ModelId: response.data._id,
            ModelFrom: 'Catering',
            StaffName: this.order.StaffName
          }
          await Api.postIn('allpayments', paymentEntry)
        }
        if (order.staffName !== '') return { to: ' CateringOrder' }
        return { to: 'ListOrder' }
      }
    } catch (error) {
      error
    }
  },

  recivePaymentTicket: async function (res, order) {
    order.AuthorizationPayment = [
      {
        state: 1,
        total: res.total,
        paymentInfo: res,
        PaymentMethod: res.method + ' ' + res.accountType
      }
    ]
    order.State = 0
    order.isTicket = true

    const response = await Api.postIn('Order', order)
    if (order.StaffName) {
      store.commit('setAllTickets', [])
      store.commit('setCart', [])
      store.commit('setOrder', {})
      return { to: 'Ticket' }
    }
    if (order.clientId !== '') {
      order = response.data
      store.commit('setOrder', response.data)
      await this.getTickets()
      return { to: 'OrderFront' }
    }
  },

  /**
   * Generates the footer html
   */
  getHtmlFooter(merchant = { name: '', phone: '', email: '' }) {
    const html = `<br><br><div style="text-align: center"><p>This is a system-generated email. Please do not reply. If you need assistance, please contact
       ${merchant.name} at  ${merchant.phone} or  ${merchant.email}</p>
      <p>To learn more about iMenuApps®, please visit <a href="https://imenuapps.com">https://imenuapps.com</a> or email us at <a href="mailto:support@imenuapps.net">support@imenuapps.net</a></p>
      <p>Copyright© 2024 iMenuApps®. All rights reserved.</p></div>`

    return html
  },

  hexStringToUint8Array(hexString) {
    const bytes = new Uint8Array(hexString.length / 2)
    for (let i = 0; i < hexString.length; i += 2) {
      bytes[i / 2] = parseInt(hexString.slice(i, i + 2), 16)
    }
    return bytes
  },
  decryptData: async function (eObj) {
    try {
      const { encryptedData, iv } = eObj
      if (!encryptedData || !iv) {
        return eObj
      }
      const key = 'rGa2ZsnlkmrkuSYejkJlRPLiPx5gdVRU'
      const keyBuffer = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(key),
        { name: 'AES-CBC', length: 256 },
        false,
        ['decrypt']
      )
      const encryptedBuffer = this.hexStringToUint8Array(encryptedData)
      const decryptedBuffer = await crypto.subtle.decrypt(
        {
          name: 'AES-CBC',
          iv: this.hexStringToUint8Array(iv) //new Uint8Array(16) // Initialization vector (IV) should be the same as used during encryption
        },
        keyBuffer,
        encryptedBuffer
      )
      return new TextDecoder().decode(decryptedBuffer)
    } catch (error) {
      // console.error('Decryption error:', error);
      return null
    }
  },

  decrypt: async function(encrypted) {
    const algorithm = 'aes-256-cbc';
    const key = 'rGa2ZsnlkmrkuSYejkJlRPLiPx5gdVRU'; // Asegúrate de que esta clave coincida con la de encriptación

    // Asegúrate de que encrypted sea un string en el formato iv:encryptedData
    if (!encrypted) {
        throw new Error('Invalid encrypted string');
    }

    const parts = encrypted.split(':'); // Divide el string en IV y datos encriptados
    const iv = Buffer.from(parts.shift(), 'hex'); // Obtiene el IV
    const encryptedData = Buffer.from(parts.join(':'), 'hex'); // Obtiene los datos encriptados

    const decipher = crypto.createDecipheriv(
        algorithm,
        Buffer.from(key),
        iv // Usa el IV directamente
    );

    let decrypted = decipher.update(encryptedData); // Desencripta los datos
    decrypted = Buffer.concat([decrypted, decipher.final()]);

    return decrypted.toString(); // Devuelve el ServerId desencriptado
  }
}
