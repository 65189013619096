import axios from 'axios'
const { Commons } = require('../../frontend/commons')
//import { ScheduledEmailNotification } from "../models/ScheduledNotification";

axios.interceptors.response.use(
  async function (response) {
    // Check if response contains encrypted data
    if (response.data.encryptedData && response.data.iv) {
      // encryptedData, iv
      // Decrypt the encrypted data
      const decryptedData = await Commons.decryptData(response.data)
      const resData = JSON.parse(decryptedData)
      // Replace encrypted data with decrypted data in response
      response.data = resData
    }
    return response
  },
  function (error) {
    console.error('Error in response interceptor:', error)
    // Handle errors here
    return Promise.reject(error)
  }
)
export var Api = {
  token:
    'M3nuSucc3ssAp1987456321M3nuSucc3ssAp1741258963M3nuSucc3ssAp1963258741',
  //baseUrl: 'http://localhost:8080', // LOCAL
  baseUrl: 'https://imenuappsdev.com', //DEV
  //baseUrl: 'https://imenuapps.net', //PROD
  //endPointURL: 'http://localhost:7071/api/', //LOCALes1 222
  //endPointURL: 'https://imenuappsapi2.azurewebsites.net/api/', //PROD
  endPointURL: 'https://imenuappsapi2-dev.azurewebsites.net/api/', //DEVELOP
  endPointURLP: 'https://apitest.authorize.net/xml/v1/request.api',

  defaultToken:
    'M3nuSucc3ssAp1987456321M3nuSucc3ssAp1741258963M3nuSucc3ssAp1963258741',

  //  token: 'eposMiddl34PI',
  // endPointURL: 'https://8akf11lds3.execute-api.us-east-1.amazonaws.com/',

  // token: 'Basic UDNQVUFONkw3QkZSSlNSWVE3Sk5NRzNDS1pVT1pSVVg6S0NBSFhUMUlWNFlITEw2Qjc5VDFWUkJDN0tET0VFMDQ=',
  // endPointURL: 'https://api.eposnowhq.com/api/v4/',
  restaurantId: '',
  restaurant: null,
  mergedOrders: [], // Store the merged orders here

  setRestaurantId: async function (restaurantId) {
    this.restaurantId = restaurantId
    if (restaurantId != '') {
      const data = await this.fetchById_Sync('Restaurant', restaurantId)
      this.restaurant = data.data
    } else this.restaurant = null
  },

  getRestaurant: function () {
    return this.restaurant
  },

  setTokenId: function (tokenId) {
    if (tokenId) this.token = tokenId
  },

  fetchAll: async function (entity, params = {}) {
    try {
      const response = await axios.get(this.endPointURL + entity, {
        headers: { Authorization: this.token, restaurantid: this.restaurantId },
        params
      })
      //console.log(`Fetched ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  deleteEvent: async function (entity, params = {}) {
    try {
      const response = await axios.delete(this.endPointURL + entity, {
        headers: { Authorization: this.token, restaurantid: this.restaurantId },
        params
      })
      //console.log(`Fetched ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getUrl: async function (entity, url) {
    try {
      const response = await axios.post(
        this.endPointURL + entity,
        { url },
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Successfully fetched URL for ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching URL for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getUrlTerms: async function (entity, url) {
    try {
      const response = await axios.post(
        this.endPointURL + entity,
        { url },
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json'
          }
        }
      )
      //console.log(`Successfully fetched URL terms for ${entity}:`,response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching URL terms for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchAddresses: async function (entity, params = {}) {
    try {
      const response = await axios.get(this.endPointURL + entity, {
        headers: { Authorization: this.token, restaurantid: this.restaurantId },
        params
      })
      //console.log(`Fetched addresses for ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching addresses for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchWorksheets: async function (params = {}) {
    try {
      const data = await this.fetchAll('worksheet', params) // Call fetchAll and await its response
      //console.log('Fetched worksheets:', data) // Log the fetched data
      return data // Return the data for further use
    } catch (error) {
      console.error('Error fetching worksheets:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchTracking: async function (entity, params) {
    try {
      const response = await axios.get(this.endPointURL + entity, {
        headers: { Authorization: this.token, restaurantid: this.restaurantId },
        params: params
      })
      //console.log(`Fetched tracking data for ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching tracking data for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchAlls: async function (entity) {
    try {
      const response = await axios.get(this.endPointURL + entity, {
        headers: { Authorization: this.token }
      })
      //console.log(`Fetched all ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching all ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchAddress: async function (entity) {
    try {
      const response = await axios.get(this.endPointURL + entity, {
        headers: { Authorization: this.token }
      })

      //console.log(`Fetched address for ${entity}:`, response.data) // Log the fetched data
      return response.data // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching address for ${entity}:`, error) // Log the error with context
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchAllFiltered: async function (entity, data) {
    try {
      const response = await axios.get(
        this.endPointURL + entity + '?filter=1',
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          },
          params: data
        }
      )
      //console.log(`Fetched filtered data for ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching filtered data for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchById: async function (entity, id) {
    try {
      const response = await axios.get(
        this.endPointURL + entity + '?id=' + id,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched ${entity} with ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching ${entity} with ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  fetchById_Sync: async function (entity, id) {
    try {
      const response = await axios.get(
        this.endPointURL + entity + '?id=' + id,
        {
          headers: {
            Authorization: this.defaultToken,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched ${entity} with ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching ${entity} with ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  postIn: async function (entity, items) {
    //console.log('Calling API at:', this.endPointURL + entity, items)
    //console.log('Token:', this.token, 'RestaurantId:', this.restaurantId)

    try {
      const response = await axios.post(this.endPointURL + entity, items, {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json',
          restaurantid: this.restaurantId
        }
      })
      //console.log(`Successfully posted to ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error posting to ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  postInBulk: async function (entity, selectedItems) {
    //console.log('Calling API at:', this.endPointURL + entity, selectedItems)

    try {
      const response = await axios.post(
        this.endPointURL + entity,
        selectedItems,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Successfully posted in bulk to ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error posting in bulk to ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  post: async function (entity, items) {
    try {
      const response = await axios.post(this.endPointURL + entity, items, {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json',
          restaurantid: this.restaurantId
        }
      })
      //console.log(`Successfully posted to ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error posting to ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  postConnect: async function (entity, items) {
    try {
      const response = await axios.post(
        this.endPointURL + 'connect' + entity,
        items,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Successfully posted to connect ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error posting to connect ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  postInDigital: async function (entity, items) {
    try {
      const response = await axios.post(this.endPointURL + entity, items, {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json'
        }
      })
      //console.log(`Successfully posted in digital to ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error posting in digital to ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  putInDigital: async function (entity, items) {
    try {
      const response = await axios.put(this.endPointURL + entity, items, {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json'
        }
      })
      //console.log(`Successfully updated in digital for ${entity}:`,response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error updating in digital for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  deleteByIdDigital: async function (entity, id) {
    try {
      const response = await axios.delete(
        this.endPointURL + entity + '?id=' + id,
        {
          headers: { Authorization: this.token }
        }
      )
      //console.log(`Successfully deleted ${entity} with ID ${id}:`,response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error deleting ${entity} with ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  login: async function (items) {
    try {
      const response = await axios.post(this.endPointURL + 'login', items, {
        headers: { 'Content-Type': 'application/json' }
      })
      //console.log('Login successful:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error during login:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  loginForgot: async function (items) {
    try {
      const response = await axios.post(
        this.endPointURL + 'login?forgotPassword=1',
        items,
        { headers: { 'Content-Type': 'application/json' } }
      )
      //console.log('Forgot password request successful:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error during forgot password request:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  putIn: async function (entity, items) {
    try {
      const response = await axios.put(this.endPointURL + entity, items, {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json',
          restaurantid: this.restaurantId
        }
      })
      //console.log(`Successfully updated ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error updating ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  putInAddress: async function (entity, id, items) {
    try {
      const response = await axios.put(
        this.endPointURL + entity + '/' + id,
        items,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json'
          }
        }
      )
      //console.log(`Successfully updated address for ${entity} with ID ${id}:`,response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(
        `Error updating address for ${entity} with ID ${id}:`,
        error
      ) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  putInTerms: async function (entity, items) {
    try {
      const response = await axios.put(this.endPointURL + entity, items, {
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json'
        }
      })
      //console.log(`Successfully updated terms for ${entity}:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error updating terms for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  changePassword: async function (items) {
    try {
      const response = await axios.put(
        this.endPointURL + 'staff?changePassword=1',
        items,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Password change successful:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error changing password:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  //delete specific food of data
  deleteFoodFromJournal: async function (entity, journalId, foodIndex) {
    try {
      const response = await axios.delete(
        `${this.endPointURL}${entity}?id=${journalId}&foodIndex=${foodIndex}`,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json'
          }
        }
      )
      //console.log(`Successfully deleted food from journal for ${entity}:`,response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error deleting food from journal for ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  deleteById: async function (entity, id) {
    try {
      const response = await axios.delete(
        this.endPointURL + entity + '?id=' + id,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Successfully deleted ${entity} with ID ${id}:`,response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error deleting ${entity} with ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  deleteByIdAddress: async function (entity, id) {
    try {
      const response = await axios.delete(
        this.endPointURL + entity + '/' + id,
        {
          headers: { Authorization: this.token }
        }
      )
      //console.log(`Successfully deleted ${entity} with ID ${id}:`,response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error deleting ${entity} with ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getProductsByCategory: async function (categoryId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'Product?category=' + categoryId,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched products for category ID ${categoryId}:`,response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(
        `Error fetching products for category ID ${categoryId}:`,
        error
      ) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  findCustomerByEmail: async function (email) {
    try {
      const response = await axios.get(
        this.endPointURL + 'Customer?email=' + email,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched customer data for email ${email}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching customer data for email ${email}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  findProductCharges: async function (id, cant) {
    try {
      const response = await axios.get(
        this.endPointURL + 'othercharges?product=' + id + '&cant=' + cant,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched product charges for product ID ${id} with quantity ${cant}:`,response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(
        `Error fetching product charges for product ID ${id} with quantity ${cant}:`,
        error
      ) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  //Add new function to get all charges on invoices, orders and quotes
  getCharges: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'othercharges', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })
      //console.log('Fetched charges:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching charges:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getAvailableShipping: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'shipping?available=1',
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Fetched available shipping options:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching available shipping options:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getAvailbleTax: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'tax?available=1', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })
      //console.log('Fetched available tax options:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching available tax options:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getAllCategoryActiveMenu: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'menu?category=1', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })
      //console.log('Fetched all active menu categories:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching all active menu categories:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  mergeOrders: async function (id) {
    try {
      // Make the API request to merge the orders
      const response = await axios.get(this.endPointURL + 'order', {
        params: {
          clientId: id // Use the actual client ID
        }
      })
      this.mergedOrders = response.data // Store the merged orders in the data property
      //console.log('Merged orders:', this.mergedOrders) // Log the merged orders
    } catch (error) {
      console.error('Error merging orders:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  mergeOrder: async function (id) {
    try {
      const response = await axios.get(
        this.endPointURL + 'order?customer=' + id,
        {
          headers: { Authorization: this.token }
        }
      )
      //console.log(`Fetched order for customer ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching order for customer ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getCustomerOrders: async function (id) {
    try {
      // Make the API request to get customer orders
      const response = await axios.get(
        this.endPointURL + 'order?customer=' + id,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched orders for customer ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching orders for customer ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  /**
   *
   * @param {String} id order Id
   * @returns details of the order
   */
  getOrderById: async function (id) {
    const headers = {
      Authorization: this.token
    }

    try {
      const response = await axios.get(this.endPointURL + 'order?id=' + id, {
        headers
      })
      //console.log(`Fetched order with ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching order with ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getOrdersAll: async function (id) {
    const headers = {
      Authorization: this.token
    }

    try {
      const response = await axios.get(
        this.endPointURL + 'order?customer=' + id,
        {
          headers
        }
      )
      //console.log(`Fetched all orders for customer ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching all orders for customer ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getCustomerOrdersAll: async function (customerId) {
    try {
      // Retrieve all registered restaurants for the client
      const restaurants = await axios.get(
        this.endPointURL + 'restaurants?customer=' + customerId,
        {
          headers: { Authorization: this.token }
        }
      )
      //console.log(`Retrieved restaurants for customer ID ${customerId}:`,restaurants.data) // Log the retrieved restaurants

      // Fetch orders for each restaurant and merge them
      const allOrders = await Promise.all(
        restaurants.data.map(async (restaurant) => {
          const response = await axios.get(
            this.endPointURL + 'order?customer=' + customerId,
            {
              headers: {
                Authorization: this.token,
                restaurantid: restaurant.restaurantId
              }
            }
          )
          //console.log(`Fetched orders for restaurant ID ${restaurant.restaurantId}:`,response.data) // Log the fetched orders
          return response
        })
      )

      // Flatten the array of orders from different restaurants
      const flattenedOrders = allOrders.flat()
      //console.log(`Flattened orders for customer ID ${customerId}:`,flattenedOrders) // Log the flattened orders
      return flattenedOrders // Return the merged orders
    } catch (error) {
      console.error(
        `Error fetching orders for customer ID ${customerId}:`,
        error
      ) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getCustomerTickets: async function (id) {
    try {
      const response = await axios.get(
        this.endPointURL + 'order?ticket=' + id,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched tickets for customer ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching tickets for customer ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  sendEmail: async function (items) {
    // Map these to the new scheduled email format
    const scheduledEmail = {
      email: items.email,
      subject: items.subject,
      body: items.mess,
      scheduledAt: new Date().getTime() + 5000 // This is almost real-time with an allowance of 5 seconds
    }

    try {
      const response = await this.scheduleEmailNotification(scheduledEmail)
      //console.log('Email scheduled successfully:', response) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error scheduling email:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }

    /*
  // Alternative method to send email directly
  return await axios.post(this.endPointURL + "order?email=1", items, {
    headers: {
      Authorization: this.token,
      "Content-Type": "application/json",
      restaurantid: this.restaurantId,
    },
  });
  */
  },

  sendSms: async function (items) {
    try {
      const response = await axios.post(
        this.endPointURL + 'order?sms=1',
        items,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('SMS sent successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error sending SMS:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  menuSinCaterin: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'menu?sinCatering=1',
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Fetched menu without catering:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching menu without catering:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  menuConCaterin: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'menu?concatering=1',
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Fetched menu with catering:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching menu with catering:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  categoryByMenuId: async function (ids) {
    // Convert the array of IDs to a suitable query string
    const idsParam = ids.join(',')

    try {
      const response = await axios.get(
        this.endPointURL + 'menu?categorybymenu=' + idsParam,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched categories for menu IDs ${idsParam}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(
        `Error fetching categories for menu IDs ${idsParam}:`,
        error
      ) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  savePickupRequest: async function (assetPickupObject) {
    //console.log('Data sent:', assetPickupObject) // Log the data being sent

    try {
      const response = await axios.post(
        this.endPointURL + 'assetpickup',
        assetPickupObject,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Pickup request saved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error saving pickup request:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  saveEditPickupRequest: async function (assetPickupObject) {
    try {
      const response = await axios.put(
        this.endPointURL + 'assetpickup',
        assetPickupObject,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Pickup request edited successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error editing pickup request:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  hasPermission: async function (user, permission) {
    let res = false

    for (let index = 0; index < user.Roles.length; index++) {
      try {
        const response = await this.fetchById_Sync('Rol', user.Roles[index])
        switch (permission) {
          case 'canViewMenu':
            res = response.data.canViewMenu
            break
          case 'canCreateMenu':
            res = response.data.canCreateMenu
            break
          case 'canEditMenu':
            res = response.data.canEditMenu
            break
          case 'canDeleteMenu':
            res = response.data.canDeleteMenu
            break
          case 'canCreateCategory':
            res = response.data.canCreateCategory
            break
          case 'canEditCategory':
            res = response.data.canEditCategory
            break
          case 'canDeleteCategory':
            res = response.data.canDeleteCategory
            break
          case 'canCreateProduct':
            res = response.data.canCreateProduct
            break
          case 'canEditProduct':
            res = response.data.canEditProduct
            break
          case 'canDeleteProduct':
            res = response.data.canDeleteProduct
            break
          case 'canCreateCustomer':
            res = response.data.canCreateCustomer
            break
          case 'canEditCustomer':
            res = response.data.canEditCustomer
            break
          case 'canDeleteCustomer':
            res = response.data.canDeleteCustomer
            break
          case 'canCreateTable':
            res = response.data.canCreateTable
            break
          case 'canEditTable':
            res = response.data.canEditTable
            break
          case 'canDeleteTable':
            res = response.data.canDeleteTable
            break
          case 'canCreateTax':
            res = response.data.canCreateTax
            break
          case 'canEditTax':
            res = response.data.canEditTax
            break
          case 'canDeleteTax':
            res = response.data.canDeleteTax
            break
          case 'canCreateShipping':
            res = response.data.canCreateShipping
            break
          case 'canEditShipping':
            res = response.data.canEditShipping
            break
          case 'canDeleteShipping':
            res = response.data.canDeleteShipping
            break
          case 'canCreateOtherCharge':
            res = response.data.canCreateOtherCharge
            break
          case 'canEditOtherCharge':
            res = response.data.canEditOtherCharge
            break
          case 'canDeleteOtherCharge':
            res = response.data.canDeleteOtherCharge
            break
          case 'canCreateUser':
            res = response.data.canCreateUser
            break
          case 'canEditUser':
            res = response.data.canEditUser
            break
          case 'canDeleteUser':
            res = response.data.canDeleteUser
            break
          case 'canCreateRole':
            res = response.data.canCreateRole
            break
          case 'canEditRole':
            res = response.data.canEditRole
            break
          case 'canDeleteRole':
            res = response.data.canDeleteRole
            break
          case 'canEditOrder':
            res = response.data.canEditOrder
            break
          default:
            break
        }
        if (res) {
          return res // Return true if permission is found
        }
      } catch (error) {
        console.error(
          `Error fetching role permissions for role ID ${user.Roles[index]}:`,
          error
        ) // Log the error
      }
    }
    return res // Return false if no permissions were found
  },

  getAllContacts: async function () {
    // Remove this URL when API is deployed.
    // var endPointURL = "http://10.110.10.201:5000/api/";

    try {
      const response = await axios.get(this.endPointURL + 'contact', {
        headers: {
          Authorization: this.token,
          restaurantid: this.restaurantId
        }
      })
      //console.log('Fetched all contacts:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching all contacts:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  createContact: async function (contact) {
    // Remove this URL when API is deployed.
    // var endPointURL = 'http://10.110.10.201:5000/api/';

    try {
      const response = await axios.post(this.endPointURL + 'contact', contact, {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })
      //console.log('Contact created successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error creating contact:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  updateContact: async function (contact) {
    // Remove this URL when API is deployed.
    // var endPointURL = 'http://10.110.10.201:5000/api/';

    try {
      const response = await axios.put(this.endPointURL + 'contact', contact, {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })
      //console.log('Contact updated successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error updating contact:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  deleteContact: async function (id) {
    // Remove this URL when API is deployed.
    // var endPointURL = 'http://10.110.10.201:5000/api/';

    try {
      const response = await axios.delete(
        this.endPointURL + 'contact?id=' + id,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Contact with ID ${id} deleted successfully:`, response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error deleting contact with ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  /**
   * Get reservations from the current restaurant
   * @param {} id
   * @returns
   */
  getReservationsFromRestaurant: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'reservation', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })
      //console.log('Fetched reservations from restaurant:', response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error fetching reservations from restaurant:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getCustomerReservations: async function (id) {
    try {
      const response = await axios.get(
        this.endPointURL + 'reservation?customer=' + id,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched reservations for customer ID ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching reservations for customer ID ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getReservationByCode: async function (id) {
    try {
      const response = await axios.get(
        this.endPointURL + 'reservation?code=' + id,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched reservation with code ${id}:`, response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(`Error fetching reservation with code ${id}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getReservationsByOrderId: async function (orderId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'reservation?orderId=' + orderId,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log(`Fetched reservations for order ID ${orderId}:`,response.data) // Log the fetched data
      return response // Return the response data for further use
    } catch (error) {
      console.error(
        `Error fetching reservations for order ID ${orderId}:`,
        error
      ) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  customerLogIn: async function (item) {
    try {
      const response = await axios.post(
        this.endPointURL + 'customer?login=1',
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Customer logged in successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error logging in customer:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  customerForgotPassword: async function (item) {
    try {
      const response = await axios.post(
        this.endPointURL + 'customer?forgotPassword=1',
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Password reset request sent successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error sending password reset request:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  customerResetPassword: async function (item) {
    try {
      const response = await axios.put(
        this.endPointURL + 'customer?changePassword=1',
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Password reset successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error resetting customer password:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  customerSoftCreate: async function (items) {
    try {
      const response = await axios.post(
        this.endPointURL + 'customer?sc=1',
        items,
        {
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/json',
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Customer soft created successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error creating customer:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  //Doing payments

  // payACH: async function(item){
  //     //console.log(this.endPointURLP);
  //     return await axios.post(this.endPointURLP , item, {headers: {'Authorization':this.token, 'Content-Type': 'application/json', 'restaurantid': this.restaurantId}})
  // },

  // payACH: async function(item){
  //     return await axios.post(this.endPointURLP, item, {headers: {'Authorization': this.token, 'restaurantid': this.restaurantId}})
  // },

  authenticateShift4: async function (item) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?activateshift4=1',
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Shift4 authentication successful:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error authenticating Shift4:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payShift4: async function (item, restaurantId, ipClient, isDelivery) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payshift4=1',
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: restaurantId,
            ipClient: ipClient,
            isDelivery: isDelivery
          }
        }
      )
      //console.log('Shift4 payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Shift4 payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  authorizeShift4: async function (item, moto, restaurantId, ipClient) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?authorizationshift4=' + moto,
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: restaurantId,
            ipClient: ipClient
          }
        }
      )
      //console.log('Shift4 authorization successful:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error authorizing Shift4:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  captureShift4: async function (invoice, moto, restaurantId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'payment?captureshift4=' + invoice + '&moto=' + moto,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Shift4 capture successful:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error capturing Shift4 payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  refoundShift4: async function (item, moto, restaurantId, isDelivery) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?refoundshift4=' + moto,
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: restaurantId,
            isDelivery: isDelivery
          }
        }
      )
      //console.log('Shift4 refund processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Shift4 refund:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  invoiceInformationShift4: async function (
    invoice,
    moto,
    restaurantId,
    isDelivery
  ) {
    try {
      const response = await axios.get(
        this.endPointURL + 'payment?invoice=' + invoice + '&moto=' + moto,
        {
          headers: {
            Authorization: this.token,
            restaurantid: restaurantId,
            isDelivery: isDelivery
          }
        }
      )
      //console.log('Shift4 invoice information retrieved successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving Shift4 invoice information:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  voidShift4: async function (invoice, moto, restaurantId, isDelivery) {
    try {
      const response = await axios.delete(
        this.endPointURL + 'payment?invoice=' + invoice + '&moto=' + moto,
        {
          headers: {
            Authorization: this.token,
            restaurantid: restaurantId,
            isDelivery: isDelivery
          }
        }
      )
      //console.log('Shift4 void successful:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error voiding Shift4 payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getInvoiceSequence: async function (restaurantId) {
    if (restaurantId == null) restaurantId = this.restaurantId

    try {
      const response = await axios.get(
        this.endPointURL + 'imenusupport?invoicenumber=1',
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('Invoice sequence retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving invoice sequence:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getQuoteSequence: async function (restaurantId) {
    if (restaurantId == null) restaurantId = this.restaurantId

    try {
      const response = await axios.get(
        this.endPointURL + 'imenusupport?quotenumber=1',
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('Quote sequence retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving quote sequence:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getCaptchaKey: async function (restaurantId) {
    if (restaurantId == null) restaurantId = this.restaurantId

    try {
      const response = await axios.get(
        this.endPointURL + 'imenusupport?captchakey=1',
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('Captcha key retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving captcha key:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getUtc: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'login?getutc=1', {
        headers: { Authorization: this.token }
      })
      //console.log('UTC retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving UTC:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payAutorizeNet: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payAutorizeNet=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Authorize.Net payment processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Authorize.Net payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payACH: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payACH=1',
        item,
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('ACH payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing ACH payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  authorizeACH: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?authorizeACH=1',
        item,
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('ACH authorization processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing ACH authorization:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  refoundAutorizeNet: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?refoundAutorizeNet=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Authorize.Net refund processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Authorize.Net refund:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getClientIp: async function () {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json')
      //console.log('Client IP retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving client IP:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payQrShift4: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payqrshift4=1',
        item,
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('QR Shift4 payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing QR Shift4 payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  validateStatusQrShift4: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?statusqrshift4=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('QR Shift4 status validated successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error validating QR Shift4 status:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  cancelQrShift4: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?cancelqrshift4=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('QR Shift4 cancellation processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing QR Shift4 cancellation:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  generateKeyTsys: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?generateKeyTsys=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Key generation for Tsys processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error generating key for Tsys:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  tokenCardTsys: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?generateTokenTsys=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Token generation for Tsys processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error generating token for Tsys:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payTsys: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payTsys=1',
        item,
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('Tsys payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Tsys payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  authorizeTsys: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?authorizeTsys=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Tsys authorization processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Tsys authorization:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  captureTsys: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?captureTsys=1',
        item,
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('Tsys capture processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Tsys capture:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  invoiceTsys: async function (invoice, restaurantId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'payment?invoiceTsys=' + invoice,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Invoice Tsys retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving invoice Tsys:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  returnWithReferenceTsys: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?returnWithReferenceTsys=1',
        item,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Return with reference for Tsys processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing return with reference for Tsys:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  voidTsys: async function (item, restaurantId) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?voidTsys=1',
        item,
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('Tsys void processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Tsys void:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  restoreBackUpFromPortal: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'setting?restorefromportal=1',
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Backup restored from portal successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error restoring backup from portal:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  restoreBackUpFromFile: async function (item) {
    try {
      const response = await axios.post(
        this.endPointURL + 'setting?restorefromfile=1',
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Backup restored from file successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error restoring backup from file:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  backUpToPortal: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'setting?backup=1', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })
      //console.log('Backup to portal initiated successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error initiating backup to portal:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  initPaxStore: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'payment?initPaxstoreDevice=1',
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Pax store device initialized successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error initializing Pax store device:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  doCreditStore: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'payment?doCreditPaxstoreDevice=1',
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Credit store operation completed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error during credit store operation:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  walletInformation: async function (item, restaurantId, ipClient) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?walletShift4=1',
        item,
        {
          headers: {
            Authorization: this.token,
            restaurantid: restaurantId,
            ipClient: ipClient
          }
        }
      )
      //console.log('Wallet information processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing wallet information:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getPaymentByInvoice: async function (invoice, restaurantId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'allpayments?invoice=' + invoice,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Payment retrieved successfully for invoice:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving payment by invoice:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getPaymentByOrder: async function (modelId, restaurantId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'allpayments?modelid=' + modelId,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Payment retrieved successfully for order:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving payment by order:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getPaymentByDateTo: async function (todate, restaurantId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'allpayments?todate=' + todate,
        {
          headers: { Authorization: this.token, restaurantid: restaurantId }
        }
      )
      //console.log('Payments retrieved successfully for date:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving payment by date:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getPaymentByDateToFrom: async function (datefrom, dateto, restaurantId) {
    try {
      const response = await axios.get(
        this.endPointURL +
          'allpayments?rangedatefrom=' +
          datefrom +
          '&rangedateto=' +
          dateto,
        { headers: { Authorization: this.token, restaurantid: restaurantId } }
      )
      //console.log('Payments retrieved successfully for date range:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving payment by date range:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  newRestaurant: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'imenusupport?newRestaurant=1',
        data,
        { headers: { Authorization: this.defaultToken } }
      )
      //console.log('New restaurant created successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error creating new restaurant:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  deleteRestaurant: async function (id) {
    try {
      const response = await axios.delete(
        this.endPointURL + 'imenusupport?deleteRestaurant=' + id,
        { headers: { Authorization: this.defaultToken } }
      )
      //console.log('Restaurant deleted successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error deleting restaurant:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  unsubscribe: async function (restId, data) {
    try {
      const response = await axios.put(
        this.endPointURL + 'login?unsubscribe=1',
        data,
        {
          headers: { Authorization: this.token, restaurantid: restId }
        }
      )
      //console.log('Unsubscribe request processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing unsubscribe request:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  testRestaurantEmail: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'setting?testEmail=1',
        data,
        {
          headers: {
            Authorization: this.defaultToken,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Test restaurant email request processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing test restaurant email request:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  testSupportEmail: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'imenusupport?testEmail=1',
        data,
        { headers: { Authorization: this.token } }
      )
      //console.log('Test support email request processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing test support email request:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payPayFabric: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payPayFabric=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('PayFabric payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing PayFabric payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  authorizePayFabric: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?authorizePayFabric=1',
        data,
        {
          headers: {
            Authorization: this.defaultToken,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('PayFabric authorization processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing PayFabric authorization:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  processPayFabric: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?processPayFabric=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('PayFabric process completed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing PayFabric:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  verifyNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?verifyNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo verification processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo verification:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  voidNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?voidNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo void processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo void:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  returnNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?returnNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo return processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo return:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  authorizeNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?authorizeNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo authorization processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo authorization:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  captureNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?captureNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo capture processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo capture:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  tipNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?tipNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo tip processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo tip:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  batchCloseNexgo: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?batchCloseNexgo=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Nexgo batch close processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing Nexgo batch close:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  payNAB: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?payNAB=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('NAB payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing NAB payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  authorizeNAB: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?authorizeNAB=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('NAB authorization processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing NAB authorization:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  incrementAuthorizeNAB: async function (data, invoice, moto) {
    try {
      const response = await axios.post(
        this.endPointURL +
          'payment?incrementAuthorizeNAB=' +
          invoice +
          '&moto=' +
          moto,
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Increment NAB authorization processed successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing increment NAB authorization:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  captureNAB: async function (data, invoice, moto) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?captureNAB=' + invoice + '&moto=' + moto,
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('NAB capture processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing NAB capture:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  refundNAB: async function (data, invoice, moto) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?refundNAB=' + invoice + '&moto=' + moto,
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('NAB refund processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing NAB refund:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  voidNAB: async function (data, invoice, moto) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?voidNAB=' + invoice + '&moto=' + moto,
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('NAB void processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing NAB void:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getInvoiceNAB: async function (invoice) {
    try {
      const response = await axios.get(
        this.endPointURL + 'payment?getInvoiceNAB=' + invoice,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Invoice NAB retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving invoice NAB:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getServerDate: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'imenusupport?getServerHour=1',
        {
          headers: { Authorization: this.token }
        }
      )
      //console.log('Server date retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving server date:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getAllCustomerCredit: async function (customerId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'customercredit?customer=' + customerId,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('All customer credit retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving all customer credit:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getActiveCustomerCredit: async function (customerId) {
    try {
      const response = await axios.get(
        this.endPointURL + 'customercredit?customerActive=' + customerId,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Active customer credit retrieved successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving active customer credit:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getRestaurantCustomer: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'order?allCustomer=1',
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('All restaurant customers retrieved successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving restaurant customers:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  customerCreditForAll: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'customercredit?createForAll=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Customer credit for all processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing customer credit for all:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  thisDateSheetHour: async function (data) {
    try {
      const response = await axios.get(
        this.endPointURL + 'worksheet?thisdate=' + data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Date sheet hour retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving date sheet hour:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  findCompleteRestaurantObject: async function () {
    try {
      const resp = await axios.get(
        this.endPointURL + 'restaurant?findCompleteObject=' + this.restaurantId,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )

      if (resp.status === 200) {
        //console.log('Complete restaurant object retrieved successfully:', resp.data) // Log the response data
        return resp.data // Return the response data
      } else {
        console.warn(
          'Failed to retrieve complete restaurant object, status:',
          resp.status
        ) // Log warning for non-200 status
        return null // Return null for non-200 status
      }
    } catch (error) {
      console.error('Error retrieving complete restaurant object:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  findRestaurantToFront: async function () {
    try {
      const resp = await axios.get(
        this.endPointURL +
          `restaurant?findRestaurantToFront=${this.restaurantId}`,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )

      if (resp.status === 200) {
        //console.log('Restaurant to front retrieved successfully:', resp.data) // Log the response data
        return resp.data // Return the response data
      } else {
        console.warn(
          'Failed to retrieve restaurant to front, status:',
          resp.status
        ) // Log warning for non-200 status
        return null // Return null for non-200 status
      }
    } catch (error) {
      console.error('Error retrieving restaurant to front:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  findCustomerInfo: async function (customerId) {
    try {
      const resp = await axios.get(
        this.endPointURL +
          `restaurant?findCustomerInfo=${this.restaurantId}&customerId=${customerId}`,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )

      if (resp.status === 200) {
        //console.log('Customer information retrieved successfully:', resp.data) // Log the response data
        return resp.data // Return the response data
      } else {
        console.warn(
          'Failed to retrieve customer information, status:',
          resp.status
        ) // Log warning for non-200 status
        return null // Return null for non-200 status
      }
    } catch (error) {
      console.error('Error retrieving customer information:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  generateTokenDoorDash: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + `payment?activateDoordash=1`,
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('DoorDash token generated successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error generating DoorDash token:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  // exportData: async function (entity, fields) {
  //   try {
  //     const response = await axios.get(this.endPointURL + 'data', {
  //       params: { fields, entity },
  //       responseType: 'blob',
  //       headers: { Authorization: this.token, restaurantid: this.restaurantId }
  //     })
  //     //console.log('Data exported successfully:', response.data) // Log the response data
  //     return response // Return the response data for further use
  //   } catch (error) {
  //     console.error('Error exporting data:', error) // Log the error
  //     throw error // Rethrow the error for further handling if needed
  //   }
  // },
  // importData: async function (entity, formData) {
  //   try {
  //     const response = await axios.post(this.endPointURL + 'data', formData, {
  //       params: { entity },
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: this.token,
  //         restaurantid: this.restaurantId
  //       }
  //     })
  //     //console.log('Data imported successfully:', response.data) // Log the response data
  //     return response // Return the response data for further use
  //   } catch (error) {
  //     console.error('Error importing data:', error) // Log the error
  //     throw error // Rethrow the error for further handling if needed
  //   }
  // },

  // Fetch available database fields for a given entity
  getEntityFields: async function(entity = {}) {
    console.log("Entity: ",entity)
    console.log("EndpointURL: ",this.endPointURL)
    try {
      const response = await axios.get(this.endPointURL + 'data/' + 'fields', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId },
        params: entity
      })
      console.log("Response: ", response)
      return response; // Returns fields for the entity
    } catch (error) {
      console.error('Error fetching entity fields:', error);
      throw new Error(error.response?.data?.error || 'Failed to fetch entity fields.');
    }
  },
 
  // Import data with field mappings and file data
  async importDataWithMapping(entity, formData) {
    try {
      const response = await axios.post(`${this.endPointURL}data/importWithMapping`, formData, {
        params: { entity },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: this.token,
          restaurantid: this.restaurantId
        },
      });
      return response.data; // Success response from the backend
    } catch (error) {
      console.error('Error importing data with mappings:', error);
      throw new Error(error.response?.data?.error || 'Failed to import data.');
    }
  },
 
  // Export data with selected fields
  async exportData(entity, selectedFields) {
    try {
      const response = await axios.get(`${this.endPointURL}data/export`, {
        params: {
          entity,
          fields: selectedFields.join(','),
        },
        headers: {
          Authorization: this.token,
          restaurantid: this.restaurantId
        },
        responseType: 'blob', // Necessary for file downloads
      });
      return response; // Returns the exported file as a blob
    } catch (error) {
      console.error('Error exporting data:', error);
      throw new Error(error.response?.data?.error || 'Failed to export data.');
    }
  },

  getCustomersByRestaurant: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'Customer?getByRestaurant=' + this.restaurantId,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Customers retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving customers by restaurant:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getProductsByRestaurant: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'Product?getByRestaurant=' + this.restaurantId,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Products retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving products by restaurant:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getReportsData: async function (module, type, params) {
    try {
      const response = await axios.get(
        this.endPointURL + `report?module=${module}&type=${type}`,
        {
          params: params,
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Reports data retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving reports data:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getProductCategories: async function () {
    try {
      const response = await axios.get(
        this.endPointURL + 'report?getProductCategories=1',
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('Product categories retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving product categories:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  ACHPayNAB: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?ACHPayNAB=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('ACH payment processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing ACH payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  ACHVoidNAB: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?ACHVoidNAB=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('ACH void processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing ACH void:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  ACHRefundNAB: async function (data) {
    try {
      const response = await axios.post(
        this.endPointURL + 'payment?ACHRefundNAB=1',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )
      //console.log('ACH refund processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing ACH refund:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  /**
   * Scheduled notification
   */

  scheduleNotification: async function (notificationData) {
    // notificationData should include method, email, mobile, subject, body, etc.
    const data = {
      ...notificationData,
      //userId: '123' // temporarily use this
    }
    console.log({data})
    return await axios.post(this.endPointURL + 'scheduledNotification', data, {
      headers: { Authorization: this.token, restaurantid: this.restaurantId }
    })
  },
  communicationResponses: async function (entity, params = {}) {
    try {
      const response = await axios.get(this.endPointURL + entity + '/emailResponses', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId },
        params
      })
      //console.log(`Fetched ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },
  scheduleEmailNotification: async function (scheduleEmailNotification) {
    try {
      // ScheduledEmailNotification
      const data = {
        ...scheduleEmailNotification,
        method: 'email',
        userId: '123' // temporarily use this
      }

      const response = await axios.post(
        this.endPointURL + 'scheduledNotification',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )

      //console.log('Scheduled email notification processed successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error processing scheduled email notification:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  /**
   * Subscription Payment
   */
  createSubscriptionPayment: async function (subscriptionPayment) {
    try {
      const data = {
        ...subscriptionPayment,
        merchantId: this.restaurantId,
        userId: '111' // temporarily use this
      }

      const response = await axios.post(
        this.endPointURL + 'subscription',
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )

      //console.log('Subscription payment created successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error creating subscription payment:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  cancelScheduledEmailNotification: async function (
    scheduleEmailNotificationId,
    userId
  ) {
    try {
      // ScheduledEmailNotification
      const data = {
        status: 'cancelled',
        userId
      }

      const response = await axios.put(
        this.endPointURL +
          `scheduledNotification/${scheduleEmailNotificationId}/cancel`,
        data,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )

      //console.log('Scheduled email notification cancelled successfully:',response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error cancelling scheduled email notification:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  /**
   * Get all addresses by the user
   */
  getAddressByUser: async function (userId, type) {
    try {
      if (type) {
        type = `&type=${type}`
      } else {
        type = ''
      }

      const response = await axios.get(
        this.endPointURL + `address?customerId=${userId}${type}`,
        {
          headers: {
            Authorization: this.token,
            restaurantid: this.restaurantId
          }
        }
      )

      //console.log('Address retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving address by user:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  getAllCustomerTransactions: async function (entity, params = {}) {
    console.log(`Fetched ${entity}:`, params)
    try {
      const response = await axios.get(this.endPointURL + entity , {
        headers: { Authorization: this.token, restaurantid: this.restaurantId },
        params
      })
      console.log(`Fetched ${entity}:`, response.data) // Log the fetched data
      return response // Return the data for further use
    } catch (error) {
      console.error(`Error fetching ${entity}:`, error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },
  getJournalByUser: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'journalentry', {
        headers: { Authorization: this.token }
      })

      //console.log('Journal entries retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving journal entries:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  /**
   * Set address as a default
   */
  setAddressAsDefault: async function (addressId) {
    try {
      const response = await axios.put(
        this.endPointURL + `address/${addressId}/makeDefault`,
        {},
        {
          headers: { Authorization: this.token }
        }
      )

      //console.log('Address set as default successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error setting address as default:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  },

  //pickup requests api
  // fetchAllPickupRequests: async function () {
  //   return await axios.get(
  //     this.endPointURL + 'assetpickup',
  //     {},
  //     {
  //       headers: { Authorization: this.token, restaurantid: this.restaurantId }
  //     }
  //   )
  // }
  fetchAllPickupRequests: async function () {
    try {
      const response = await axios.get(this.endPointURL + 'assetpickup', {
        headers: { Authorization: this.token, restaurantid: this.restaurantId }
      })

      //console.log('Pickup requests retrieved successfully:', response.data) // Log the response data
      return response // Return the response data for further use
    } catch (error) {
      console.error('Error retrieving pickup requests:', error) // Log the error
      throw error // Rethrow the error for further handling if needed
    }
  }
}
